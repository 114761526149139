import React from "react";

const UserProfileSkeleton = () => {
  return (
    <div className="flex flex-col items-center mt-16 gap-8">
      <div className="bg-gray-300 rounded-lg max-w-md w-full h-[697px] animate-pulse"></div>
      <div className="bg-gray-300 rounded-lg max-w-md w-full h-[370px] animate-pulse"></div>
    </div>
  );
};

export default UserProfileSkeleton;
