import React, { useState } from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { formatLocation } from "../../../utils/formatLocation";
import GigDetails from "../../GigDetails";
import { IconButton } from "../../UI";

const UpcomingGigSection = ({ gigs, handleDeleteGig, handleGigClick, handleCloseModal, selectedGig }) => {
  const [isDeleteGig, setIsDeleteGig] = useState(false);

  const navigate = useNavigate();

  const handleEditGig = (gig) => {
    navigate(`/edit-gig/${gig.id}`);
  };

  return (
    <ul className="mt-5">
      {gigs.map((gig) => (
        <React.Fragment key={gig.id}>
          <li
            className="flex justify-between items-center px-3 py-4 transition duration-300 hover:bg-black/5 cursor-pointer hover:rounded-lg"
            onClick={() => handleGigClick(gig)}
          >
            <div className="flex-grow cursor-pointer">
              <p className="font-bold text-md">
                {gig.date} - {gig.event_name}
              </p>
              <p className="text-sm text-gray-500">
                <span className="font-medium">{gig.venue_name}</span> — {formatLocation(gig)}
              </p>
            </div>
            <div className="flex items-center sm:space-x-2 flex-col sm:flex-row justify-center">
              <IconButton handleFunc={() => handleEditGig(gig)}>
                <FiEdit className="size-5" />
              </IconButton>
              <IconButton handleFunc={() => setIsDeleteGig(true)}>
                <FiTrash2 className="size-5 text-red-600" />
              </IconButton>
            </div>
          </li>
          <hr className="" />

          <DialogRadix.Root open={isDeleteGig} onOpenChange={setIsDeleteGig}>
            <DialogRadix.Portal>
              <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
              <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[575px] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                <DialogRadix.Title className="mb-6 text-primary text-xl font-bold">{"Delete Gig?"}</DialogRadix.Title>
                <DialogRadix.Description className="mb-4 text-primary text-md">
                  Are you sure you want to delete this gig?
                </DialogRadix.Description>

                <div className="flex mt-6 gap-5 justify-end">
                  <DialogRadix.Close asChild>
                    <button className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3">
                      Cancel
                    </button>
                  </DialogRadix.Close>

                  <button
                    onClick={() => {
                      setIsDeleteGig(false);
                      handleDeleteGig(gig);
                    }}
                    className="text-sm bg-white text-red-600 border border-red-600 p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3"
                  >
                    Delete
                  </button>
                </div>
              </DialogRadix.Content>
            </DialogRadix.Portal>
          </DialogRadix.Root>

          <DialogRadix.Root open={selectedGig !== null} onOpenChange={handleCloseModal}>
            <DialogRadix.Portal>
              <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
              <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[80%] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                {selectedGig && <GigDetails gig={selectedGig} />}
              </DialogRadix.Content>
            </DialogRadix.Portal>
          </DialogRadix.Root>
        </React.Fragment>
      ))}
    </ul>
  );
};

export default UpcomingGigSection;
