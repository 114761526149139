import React, { useEffect, useState } from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { BsInfoCircle } from "react-icons/bs";
import { FiCalendar, FiCheck, FiCopy, FiEdit, FiTrash2, FiX } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { Avatar, IconButton, Input, Tooltip } from "./../UI";
import ArtistProfileSkeleton from "./components/ArtistProfileSkeleton";
import { handleCopyArtistPageUrl, handleCopyCalendarUrl } from "./helpers";

const ArtistProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [artist, setArtist] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [editedNotes, setEditedNotes] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);

  useEffect(() => {
    fetchArtist();
  }, [id]);

  const fetchArtist = async () => {
    const { data, error } = await supabase.from("artists").select("*").eq("id", id).single();

    if (error) {
      console.error("Error fetching artist:", error);
      toast.error(`Error fetching artist: ${error.message}`, { id: "errorFetchingArtist" });
    } else {
      setArtist(data);
      setEditedName(data.name);
      setEditedNotes(data.notes || "");
    }
  };

  const handleImageUpload = async (event) => {
    try {
      setUploading(true);
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error("You must select an image to upload.");
      }

      const file = event.target.files[0];
      const fileExt = file.name.split(".").pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const filePath = `${fileName}`;

      let { error: uploadError } = await supabase.storage.from("artist-images").upload(filePath, file);

      if (uploadError) {
        throw uploadError;
      }

      const { data, error } = await supabase.from("artists").update({ avatar_url: filePath }).eq("id", id).select();

      if (error) {
        throw error;
      }

      setArtist(data[0]);
      toast.success("Image uploaded successfully", { id: "successfullyUploadingPhoto" });
    } catch (error) {
      toast.error(`Error uploading image: ${error.message}`, { id: "errorUploadingPhoto" });
    } finally {
      setUploading(false);
    }
  };

  const handleEdit = async () => {
    try {
      const { data, error } = await supabase
        .from("artists")
        .update({ name: editedName, notes: editedNotes })
        .eq("id", id)
        .select();

      if (error) throw error;

      setArtist(data[0]);
      setEditing(false);
      toast.success("Artist updated successfully", { id: "successfullyUpdatedArtist" });
    } catch (error) {
      toast.error(`Error updating artist: ${error.message}`, { id: "errorUpdatingArtist" });
    }
  };

  const handleDelete = async () => {
    try {
      // First, delete associated gigs
      const { error: gigsDeleteError } = await supabase.from("gigs").delete().eq("artist", id);

      if (gigsDeleteError) throw gigsDeleteError;

      // Delete the calendar file
      const { error: calendarDeleteError } = await supabase.storage.from("calendars").remove([`${id}.ics`]);

      if (calendarDeleteError) throw calendarDeleteError;

      // Then, delete the artist
      const { error: artistDeleteError } = await supabase.from("artists").delete().eq("id", id);

      if (artistDeleteError) throw artistDeleteError;

      setDeleteConfirmation(false);

      toast.success("Artist, associated gigs, and calendar deleted successfully", { id: "successfullyDeleteArtist" });
      navigate("/artists");
    } catch (error) {
      toast.error(`Error deleting artist: ${error.message}`, { id: "errorDeletingArtist" });
    }
  };

  const handleChangeEditedName = (name) => setEditedName(name);

  if (!artist) {
    return <ArtistProfileSkeleton />;
  }

  return (
    <div className="max-w-4xl mx-auto">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ type: "spring", stiffness: 100, damping: 20 }}
        className="bg-white shadow-custom rounded-xl p-8 w-full mb-8"
      >
        <div className="flex items-center gap-6">
          <div>
            <Avatar artist={artist} size={120} />
          </div>
          <div className="flex-1">
            {editing ? (
              <Input value={editedName} placeholder={""} name="djName" handleFunc={handleChangeEditedName} />
            ) : (
              <h1 className="font-bold text-4xl">{artist.name}</h1>
            )}
          </div>
          <div className="flex items-center gap-2">
            {editing ? (
              <>
                <IconButton handleFunc={handleEdit}>
                  <FiCheck className="size-6" />
                </IconButton>

                <IconButton handleFunc={() => setEditing(false)}>
                  <FiX className="size-6" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton handleFunc={() => setEditing(true)}>
                  <FiEdit className="size-6" />
                </IconButton>
                <IconButton handleFunc={() => setDeleteConfirmation(true)}>
                  <FiTrash2 className="size-6 text-red-600" />
                </IconButton>
              </>
            )}
          </div>
        </div>

        <div className="mt-4">
          <input
            accept="image/*"
            style={{ display: "none" }}
            id="raised-button-file"
            type="file"
            onChange={handleImageUpload}
          />
          <label htmlFor="raised-button-file" className={`${uploading ? "cursor-default" : "cursor-pointer"}`}>
            <span
              className={`bg-white border-2 border-primary text-primary py-2 px-4 rounded-[20px] font-medium transition-all duration-300 
    ${uploading ? "hover:none opacity-50" : "hover:-translate-y-0.5 hover:shadow-custom"}`}
            >
              {uploading ? "Uploading..." : "Change Image"}
            </span>
          </label>
        </div>

        <hr className="my-4 border-t border-gray-200" />

        <div>
          <h6 className="font-bold text-xl mb-1">Notes</h6>
          {editing ? (
            <textarea
              value={editedNotes}
              onChange={(e) => setEditedNotes(e.target.value)}
              className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-2 border-primary focus:outline-none focus:ring-0 peer focus-within:border-primary`}
              rows="5"
            ></textarea>
          ) : (
            <p>{artist.notes || "No notes available."}</p>
          )}
        </div>

        <hr className="my-4 border-t border-gray-200" />

        <div>
          <div className="flex gap-3 ">
            <h6 className="flex items-center font font-bold text-xl mb-1">Artist Page URL</h6>

            <Tooltip
              place={"bottom"}
              tooltipText={
                "This artist page is a public url, allowing artists and their team to easily access gig details on the go. Please note: anyone with this link can view all information. We recommend sharing it only with trusted individuals."
              }
            >
              <span className="cursor-help mt-[3px]">
                <BsInfoCircle className="size-5" />
              </span>
            </Tooltip>
          </div>

          <div className="flex items-center">
            <p className="mr-2 break-all">{`${window.location.origin}/artist/${id}`}</p>
            <IconButton handleFunc={() => handleCopyArtistPageUrl(id)}>
              <FiCopy className="size-5" />
            </IconButton>
          </div>
        </div>

        <hr className="my-4 border-t border-gray-200" />

        <div>
          <h6 className="font-bold text-xl mb-1">iCalendar</h6>
          {artist.calendar_url ? (
            <div className="mt-1">
              <div className="flex items-center mb-1">
                <p className="mr-2 break-all">{artist.calendar_url}</p>
                <IconButton handleFunc={() => handleCopyCalendarUrl(artist.calendar_url)}>
                  <FiCopy className="size-5" />
                </IconButton>
              </div>
              <a href={`webcal://${new URL(artist.calendar_url).host}${new URL(artist.calendar_url).pathname}`}>
                <button className="flex items-center gap-2 px-3 text-sm bg-white text-primary border-2 border-primary py-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3">
                  <FiCalendar className="size-5" />
                  Subscribe to Calendar
                </button>
              </a>
            </div>
          ) : (
            <p>No calendar available for this artist.</p>
          )}
        </div>
      </motion.div>

      <DialogRadix.Root open={deleteConfirmation} onOpenChange={setDeleteConfirmation}>
        <DialogRadix.Portal>
          <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
          <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[575px] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <DialogRadix.Title className="mb-6 text-primary text-xl font-bold">
              {"Delete Artist, Associated Gigs, and Calendar?"}
            </DialogRadix.Title>
            <DialogRadix.Description className="mb-4 text-primary text-md">
              Are you sure you want to delete this artist? This action will also delete all associated gigs, the
              artist's calendar, and cannot be undone.
            </DialogRadix.Description>

            <div className="flex mt-6 gap-5 justify-end">
              <DialogRadix.Close asChild>
                <button className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3">
                  Cancel
                </button>
              </DialogRadix.Close>

              <button
                onClick={handleDelete}
                className="text-sm bg-white text-red-600 border border-red-600 p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3"
              >
                Delete
              </button>
            </div>
          </DialogRadix.Content>
        </DialogRadix.Portal>
      </DialogRadix.Root>
    </div>
  );
};

export default ArtistProfile;
