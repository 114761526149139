import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Avatar } from "../../UI";

const SKELETON_ITEMS = 5;

const ArtistList = ({ artists, isLoadingArtist }) => {
  if (isLoadingArtist)
    return (
      <div className="flex flex-col gap-2">
        {Array(SKELETON_ITEMS)
          .fill(null)
          .map((_, index) => (
            <div className="bg-gray-300 w-full rounded-lg h-[61px] animate-pulse" key={index}></div>
          ))}
      </div>
    );

  return (
    <>
      {!artists.length ? (
        <p>No artists linked to this account yet.</p>
      ) : (
        <ul>
          {artists.map((artist, index) => (
            <motion.li
              key={artist.id}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              className="flex items-center py-2 px-4 mb-1 rounded-lg transition-colors hover:bg-gray-100"
            >
              <Link to={`/artists/${artist.id}`} className="flex items-center no-underline text-inherit w-full">
                <div className="flex-shrink-0">
                  <Avatar artist={artist} />
                </div>
                <div className="ml-4">
                  <p className="text-md font-medium text-primary">{artist.name}</p>
                </div>
              </Link>
            </motion.li>
          ))}
        </ul>
      )}
    </>
  );
};

export default ArtistList;
