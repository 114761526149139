import React, { useEffect } from "react";
import { RiLock2Line } from "react-icons/ri";
import { supabase } from "../../supabaseClient";
import AuthTabs from "./components/AuthTabs";

const Auth = () => {
  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange((event, session) => {
      console.log("Auth state changed:", event, session);
    });

    return () => {
      if (authListener && authListener.unsubscribe) {
        authListener.unsubscribe();
      }
    };
  }, []);

  return (
    <div className="flex justify-center items-center min-h-screen bg-backgroundDefault">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg">
        <div className="p-8">
          <div className="flex flex-col items-center mb-6">
            <RiLock2Line size={40} className="mb-2" />
            <h1 className="text-3xl font-bold">GigDaddy</h1>
          </div>

          <AuthTabs />
        </div>
      </div>
    </div>
  );
};

export default Auth;
