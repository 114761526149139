import React from "react";
import { RiAttachment2 } from "react-icons/ri";

const GigDetails = ({ gig }) => {
  if (!gig) {
    return (
      <div className="bg-red-500 shadow-custom rounded-lg p-8 w-full mb-8 flex justify-center">
        <h6 className="items-center text-white text-xl">Gig not found</h6>
      </div>
    );
  }

  const renderField = (label, value) => {
    if (value !== null && value !== undefined && value !== "") {
      return (
        <div key={label} className="mb-2">
          <span className="font-bold text-md text-secondaryText">{label}:</span>
          <p className="text-md text-primary">{value}</p>
        </div>
      );
    }
    return null;
  };

  const renderSection = (title, fields, data) => {
    const renderedFields = fields
      .map((field) => {
        const value = field.key.split(".").reduce((obj, key) => obj && obj[key], data);
        return renderField(field.label, value);
      })
      .filter(Boolean);

    if (renderedFields.length === 0) {
      return (
        <div className="mb-6">
          <h6 className="text-xl font-bold text-primary mb-2">{title}</h6>
          <p className="text-sm italic text-primary">No information added</p>
        </div>
      );
    }

    return (
      <div className="mb-6">
        <h6 className="text-xl font-bold text-primary mb-3">{title}</h6>
        {renderedFields}
      </div>
    );
  };

  const renderAttachments = (title, attachments) => {
    if (!attachments || attachments.length === 0) {
      return null;
    }

    return (
      <div className="mb-6">
        <h6 className="text-xl font-bold text-primary mb-2">{title}</h6>
        {attachments.map((attachment, index) => (
          <div className="mb=2 flex items-center" key={index}>
            <RiAttachment2 className="size-5 mr-2 text-secondary" />
            <a href={attachment.url} target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">
              {attachment.name}
            </a>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="p-6 rounded-xl max-h-[80vh] overflow-y-auto shadow-custom">
      <h4 className="text-primary text-2xl font-bold mb-2">{gig.event_name}</h4>

      {renderSection(
        "Basic Information",
        [
          { label: "Artist", key: "artists.name" },
          { label: "Date", key: "date" },
          { label: "Event Name", key: "event_name" },
          { label: "Venue Name", key: "venue_name" },
          { label: "Venue Address", key: "venue_address" },
          { label: "City", key: "city" },
          { label: "State", key: "state" },
          { label: "Country", key: "country" },
        ],
        gig
      )}

      <hr className="my-6 border-t border-gray-300" />

      {renderSection(
        "Travel Details",
        [
          { label: "Travel Mode", key: "travel_mode" },
          { label: "Outbound Flight Number", key: "outbound_plane_number" },
          { label: "Outbound Train Number", key: "outbound_train_number" },
          {
            label: "Outbound Confirmation Number",
            key: "outbound_confirmation_number",
          },
          {
            label: "Outbound Train Booking Reference / PNR",
            key: "outbound_train_booking_reference",
          },
          { label: "Outbound Date", key: "outbound_date" },
          { label: "Outbound Time", key: "outbound_time" },
          {
            label: "Outbound Departure Airport",
            key: "outbound_departure_airport",
          },
          {
            label: "Outbound Arrival Airport",
            key: "outbound_arrival_airport",
          },
          {
            label: "Outbound Departure Train Station",
            key: "outbound_departure_train_station",
          },
          {
            label: "Outbound Arrival Train Station",
            key: "outbound_arrival_train_station",
          },
          { label: "Outbound Seat(s)", key: "outbound_seat" },
          { label: "Outbound Ticket Number", key: "outbound_ticket_number" },
          { label: "Return Flight Number", key: "return_plane_number" },
          { label: "Return Train Number", key: "return_train_number" },
          {
            label: "Return Confirmation Number",
            key: "return_confirmation_number",
          },
          {
            label: "Return Train Booking Reference / PNR",
            key: "return_train_booking_reference",
          },
          { label: "Return Date", key: "return_date" },
          { label: "Return Time", key: "return_time" },
          {
            label: "Return Departure Airport",
            key: "return_departure_airport",
          },
          { label: "Return Arrival Airport", key: "return_arrival_airport" },
          {
            label: "Return Departure Train Station",
            key: "return_departure_train_station",
          },
          {
            label: "Return Arrival Train Station",
            key: "return_arrival_train_station",
          },
          { label: "Return Seat(s)", key: "return_seat" },
          { label: "Return Ticket Number", key: "return_ticket_number" },
          { label: "Travel Notes", key: "transport_notes" },
        ],
        gig
      )}

      {renderAttachments("Travel Attachments", gig.attachments?.travel_attachments)}

      <hr className="my-6 border-t border-gray-300" />

      {renderSection(
        "Event Details",
        [
          { label: "Performance Type", key: "performance_type" },
          { label: "Soundcheck Time", key: "soundcheck_time" },
          { label: "Show Time", key: "show_time" },
          { label: "Doors Open Time", key: "doors_open_time" },
          { label: "Curfew Time", key: "curfew_time" },
          { label: "Duration", key: "duration" },
          { label: "Stage Name", key: "stage_name" },
          { label: "Lineup", key: "lineup" },
          { label: "Guest List", key: "guest_list" },
          { label: "Other Info", key: "other_info" },
        ],
        {
          ...gig,
          duration:
            gig.duration_hours || gig.duration_minutes
              ? `${gig.duration_hours || 0}h ${gig.duration_minutes || 0}m`
              : null,
        }
      )}

      <hr className="my-6 border-t border-gray-300" />

      {renderSection(
        "Transportation",
        [
          {
            label: "Airport or Train Station Pickup - Driver Name",
            key: "airport_pickup_contact_name",
          },
          {
            label: "Airport or Train Station Pickup - Driver Phone",
            key: "airport_pickup_contact_phone",
          },
          {
            label: "Airport or Train Station Pickup - Other Info",
            key: "airport_pickup_instructions",
          },
          {
            label: "Venue Pickup - Driver Name",
            key: "venue_transportation_pickup_driver_name",
          },
          {
            label: "Venue Pickup - Driver Phone",
            key: "venue_transportation_pickup_driver_phone",
          },
          {
            label: "Venue Pickup - Time",
            key: "venue_transportation_pickup_time",
          },
          {
            label: "Venue Pickup - Driver Notes",
            key: "venue_transportation_pickup_driver_notes",
          },
          {
            label: "Venue Return - Driver Name",
            key: "venue_transportation_return_driver_name",
          },
          {
            label: "Venue Return - Driver Phone",
            key: "venue_transportation_return_driver_phone",
          },
          {
            label: "Venue Return - Time",
            key: "venue_transportation_return_time",
          },
          {
            label: "Venue Return - Driver Notes",
            key: "venue_transportation_return_driver_notes",
          },
          {
            label: "Airport or Train Station Return - Driver Name",
            key: "airport_return_contact_name",
          },
          {
            label: "Airport or Train Station Return - Driver Phone",
            key: "airport_return_contact_phone",
          },
          {
            label: "Airport or Train Station Return - Pickup Time",
            key: "airport_return_pickup_time",
          },
          {
            label: "Airport or Train Station Return - Other Info",
            key: "airport_return_instructions",
          },
          { label: "Transportation Notes", key: "transportation_notes" },
        ],
        gig
      )}

      <hr className="my-6 border-t border-gray-300" />

      {renderSection(
        "Accommodation",
        [
          { label: "Hotel Name", key: "hotel_name" },
          { label: "Hotel Address", key: "hotel_address" },
          { label: "Hotel Phone", key: "hotel_phone" },
          { label: "Hotel Website", key: "hotel_website" },
          { label: "Hotel Notes", key: "hotel_notes" },
        ],
        gig
      )}

      <hr className="my-6 border-t border-gray-300" />

      {renderSection(
        "Contacts",
        [
          { label: "Event Contact 1 - Name", key: "event_contact1_name" },
          { label: "Event Contact 1 - Phone", key: "event_contact1_phone" },
          { label: "Event Contact 1 - Email", key: "event_contact1_email" },
          { label: "Event Contact 1 - Role", key: "event_contact1_role" },
          { label: "Event Contact 2 - Name", key: "event_contact2_name" },
          { label: "Event Contact 2 - Phone", key: "event_contact2_phone" },
          { label: "Event Contact 2 - Email", key: "event_contact2_email" },
          { label: "Event Contact 2 - Role", key: "event_contact2_role" },
        ],
        gig
      )}

      <hr className="my-6 border-t border-gray-300" />

      {renderAttachments("Attachments", gig.attachments?.general_attachments)}
    </div>
  );
};

export default GigDetails;
