import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { supabase } from "./supabaseClient";
import { getCurrentUser, isAdmin } from "./utils/auth";
import "./fonts.css";
import { Toaster } from "react-hot-toast";
import Header from "./components/UI/Header";
import Routes from "./Routes";

function App() {
  const [session, setSession] = useState(null);
  const [isAdminUser, setIsAdminUser] = useState(false);
  const [navigationItems, setNavigationItems] = useState([
    { text: "Home", path: "/" },
    { text: "Artists", path: "/artists" },
    { text: "New Gig", path: "/gigs/new" },
    { text: "My Profile", path: "/profile" },
  ]);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      checkAdminStatus(session?.user);
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      checkAdminStatus(session?.user);
    });

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (isAdminUser) {
      setNavigationItems((prev) => [...prev, { text: "Admin Dashboard", path: "/admin" }]);
    }
  }, [isAdminUser]);

  const checkAdminStatus = async (user) => {
    if (user) {
      const adminStatus = await isAdmin(user);
      setIsAdminUser(adminStatus);
    } else {
      setIsAdminUser(false);
    }
  };

  const handleResetAdminUser = () => {
    setIsAdminUser(false);
  };

  return (
    <div className="font-primary font-inter text-primary">
      <Router>
        <Header navigationItems={navigationItems} session={session} handleResetAdminUser={handleResetAdminUser} />

        <div className={"max-w-[1152px] mx-auto px-6"}>
          <div className="my-4">
            <Routes isAdminUser={isAdminUser} session={session} />
          </div>
        </div>
      </Router>

      <Toaster
        containerStyle={{
          zIndex: 99999,
        }}
      />
    </div>
  );
}

export default App;
