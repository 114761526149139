import React, { useEffect, useState } from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { FiCalendar, FiClock, FiMapPin } from "react-icons/fi";
import { useParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { formatLocation } from "../../utils/formatLocation";
import GigDetails from "./../GigDetails";
import { Avatar } from "./../UI";

const ArtistPublicPage = () => {
  const { id } = useParams();

  const [artist, setArtist] = useState(null);
  const [gigs, setGigs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedGig, setSelectedGig] = useState(null);

  useEffect(() => {
    fetchArtistAndGigs();
  }, [id]);

  const fetchArtistAndGigs = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data: artistData, error: artistError } = await supabase.from("artists").select("*").eq("id", id).single();

      if (artistError) {
        console.error("Error fetching artist:", artistError);
        setError("Unable to fetch artist information. Please try again later.");
        return;
      }

      setArtist(artistData);

      const { data: gigsData, error: gigsError } = await supabase
        .from("gigs")
        .select("*")
        .eq("artist", id)
        .gte("date", new Date().toISOString().split("T")[0])
        .order("date", { ascending: true });

      if (gigsError) {
        console.error("Error fetching gigs:", gigsError);
        setError("Unable to fetch gig information. Please try again later.");
        return;
      }

      setGigs(gigsData || []);
    } catch (error) {
      console.error("Unexpected error:", error);
      setError("An unexpected error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleGigClick = (gig) => {
    setSelectedGig(gig);
  };

  const handleCloseModal = () => {
    setSelectedGig(null);
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-2 border-primary border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-red-600 text-xl">{error}</p>
      </div>
    );
  }

  if (!artist) {
    return (
      <div className="flex justify-center items-center h-screen">
        <p className="text-xl text-primary text-center">Artist not found.</p>
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto p-2 sm:p-4">
      <>
        <div className="bg-white flex flex-col sm:flex-row shadow-custom rounded-lg p-8 w-full mb-8 items-center">
          <Avatar artist={artist} size={120} className={"mb-2 sm:mb-0 mr-0 sm:mr-6"} />

          <h3 className="font-bold text-primary text-2xl sm:text-3xl ">{artist.name}</h3>
        </div>

        <h5 className="text-2xl mb-4 font-bold text-secondary">Upcoming Gigs</h5>
        {gigs.length === 0 ? (
          <p className="text-xl text-primary text-center">No upcoming gigs scheduled.</p>
        ) : (
          <ul>
            {gigs.map((gig) => (
              <React.Fragment key={gig.id}>
                <div
                  key={gig.id}
                  className="mb-4 rounded-lg overflow-hidden shadow-md transition-all duration-300 hover:transform hover:-translate-y-1 hover:shadow-lg"
                >
                  <li
                    onClick={() => handleGigClick(gig)}
                    className="flex flex-col items-start bg-white py-6 px-4 transition-all duration-300 hover:bg-gray-100 cursor-pointer"
                  >
                    <div className="mb-2">
                      <h6 className="font-bold text-primary text-xl mb-1">{gig.event_name}</h6>
                    </div>
                    <div>
                      <p className="flex items-center mb-1 text-base">
                        <FiCalendar className="size-5 mr-2 text-gray-500" />
                        {gig.date}
                      </p>
                      <p className="flex items-center mb-1 text-base">
                        <FiMapPin className="size-5 mr-2 text-gray-500" />
                        {gig.venue_name}, {formatLocation(gig)}
                      </p>
                      <p className="flex items-center text-base">
                        <FiClock className="size-5 mr-2 text-gray-500" />
                        Show Time: {gig.show_time}
                      </p>
                    </div>
                  </li>
                </div>
                <DialogRadix.Root open={selectedGig !== null} onOpenChange={handleCloseModal}>
                  <DialogRadix.Portal>
                    <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
                    <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[80%] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                      {selectedGig && <GigDetails gig={selectedGig} />}
                    </DialogRadix.Content>
                  </DialogRadix.Portal>
                </DialogRadix.Root>
              </React.Fragment>
            ))}
          </ul>
        )}
      </>
    </div>
  );
};

export default ArtistPublicPage;
