import React from "react";
import { FaCalendarAlt, FaUsers } from "react-icons/fa";
import { GoPaperAirplane } from "react-icons/go";

const LandingPageContent = () => {
  return (
    <>
      <h6 className="mt-16 mb-8 text-success font-bold text-xl">100% Free to Use While We're in Beta</h6>

      <div className={`grid grid-cols-1 md:grid-cols-2 gap-2 sm:gap-4 mt-16 p-[32px]`}>
        <div className="col-span-1">
          <div className={`bg-white p-4 sm:p-8  h-full flex flex-col rounded-lg shadow-custom`}>
            <div className="flex items-center mb-4">
              <FaCalendarAlt className="sm:w-8 w-6 sm:h-8 h-6 mr-4" />
              <h6 className={`text-lg sm:text-xl text-primary ml-2`}>Easy Scheduling, Instant Access</h6>
            </div>
            <ul className={`text-left pl-5 flex-grow text-sm sm:text-base text-gray-600`}>
              <li>
                <strong>Simple Gig Entry:</strong> Add your gigs quickly and easily, all in one place.
              </li>
              <li>
                <strong>Calendar Integration:</strong> Sync with Google Calendar and other major calendar apps
                effortlessly.
              </li>
              <li>
                <strong>Access Anywhere:</strong> View your gig details on any device, anytime you need them.
              </li>
            </ul>
          </div>
        </div>

        <div className="col-span-1">
          <div className={`bg-primary p-4 sm:p-8  h-full flex flex-col rounded-lg shadow-custom`}>
            <div className="flex items-center mb-4">
              <GoPaperAirplane className="sm:w-8 w-6 sm:h-8 h-6 mr-4 text-white" />

              <h6 className={`text-lg sm:text-xl text-white ml-2`}>Travel Made Simple</h6>
            </div>
            <ul className={`text-left pl-5 flex-grow text-sm sm:text-base text-white`}>
              <li>
                <strong>Easy Flight Input:</strong> Just enter your flight number, and we'll fill in the rest.
              </li>
              <li>
                <strong>Venue Details:</strong> Keep all important venue information in one easy-to-access place.
              </li>
              <li>
                <strong>Customizable Fields:</strong> Add the details that matter most to you, from gear lists to
                setlist notes.
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="mt-24">
        <div className="flex items-center justify-center mb-4">
          <FaUsers className="sm:w-8 w-6 sm:h-8 h-6 mr-4" />
          <h4 className={`text-2xl sm:text-3xl text-primary ml-4`}>Perfect for Solo Artists and Small Teams</h4>
        </div>
        <h6 className={`text-lg sm:text-xl text-gray-600 mb-4`}>
          DJs | Musicians | Bands | Comedians | Any Touring Artist
        </h6>
      </div>
    </>
  );
};

export default LandingPageContent;
