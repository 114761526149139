import React from "react";
import { Input } from "../../UI";
import TimeSelector from "../../UI/TimeSelector";
import TextAreaGigForm from "./TextAreaGigForm";

const Transportation = ({ gigData, handleChange, handleTimeChange }) => {
  return (
    <>
      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Airport or Train Station Pickup</h6>
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Driver Name"}
          name="airport_pickup_contact_name"
          value={gigData.airport_pickup_contact_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Driver Phone"}
          name="airport_pickup_contact_phone"
          value={gigData.airport_pickup_contact_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Other Info"}
          name="airport_pickup_instructions"
          value={gigData.airport_pickup_instructions || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>

      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Venue Transportation</h6>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Pickup Driver Name"}
          name="venue_transportation_pickup_driver_name"
          value={gigData.venue_transportation_pickup_driver_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Pickup Driver Phone"}
          name="venue_transportation_pickup_driver_phone"
          value={gigData.venue_transportation_pickup_driver_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.venue_transportation_pickup_time}
          handleChangeFunc={handleTimeChange}
          name={"venue_transportation_pickup_time"}
          placeholder="Pickup Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Pickup Driver Notes"
          name="venue_transportation_pickup_driver_notes"
          value={gigData.venue_transportation_pickup_driver_notes || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Return Driver Name"}
          name="venue_transportation_return_driver_name"
          value={gigData.venue_transportation_return_driver_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Driver Phone"
          name="venue_transportation_return_driver_phone"
          value={gigData.venue_transportation_return_driver_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.venue_transportation_return_time}
          handleChangeFunc={handleTimeChange}
          name={"venue_transportation_return_time"}
          placeholder="Return Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Driver Notes"
          name="venue_transportation_return_driver_notes"
          value={gigData.venue_transportation_return_driver_notes || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>

      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Airport or Train Station Return</h6>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Driver Name"
          name="airport_return_contact_name"
          value={gigData.airport_return_contact_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Driver Phone"
          name="airport_return_contact_phone"
          value={gigData.airport_return_contact_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.airport_return_pickup_time}
          handleChangeFunc={handleTimeChange}
          name={"airport_return_pickup_time"}
          placeholder="Pickup Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Other Info"
          name="airport_return_instructions"
          value={gigData.airport_return_instructions || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>

      <div className="col-span-12">
        <TextAreaGigForm
          placeholder="Transportation Notes"
          name="transportation_notes"
          rows={3}
          value={gigData.transportation_notes || ""}
          handleFunc={handleChange}
        />
      </div>
    </>
  );
};

export default Transportation;
