import React, { useEffect, useState } from "react";
import { loadGoogleMapsScript } from "../../utils/loadGoogleMapsApi";

const GoogleMapsLoader = ({ children }) => {
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState(false);

  useEffect(() => {
    loadGoogleMapsScript()
      .then(() => {
        setGoogleMapsLoaded(true);
      })
      .catch((error) => {
        console.error("Error loading Google Maps API:", error);
      });
  }, []);

  if (!googleMapsLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  return <>{children}</>;
};

export default GoogleMapsLoader;
