import React, { useState } from "react";
import toast from "react-hot-toast";
import { MdLockReset } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { CircularProgress, Input } from "./../UI";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: email,
        options: {
          emailRedirectTo: `${window.location.origin}/reset-password`,
        },
      });

      if (error) throw error;

      toast.success(
        "If an account exists with this email, a magic link has been sent. Please check your email to reset your password.",
        { id: "sentMagicLink", duration: 6000 }
      );
      setTimeout(() => navigate("/login"), 5000);
    } catch (error) {
      console.error("Error in sending magic link:", error);
      let errorMessage = "An error occurred. Please try again later.";
      if (error.message) {
        errorMessage = `Error: ${error.message}`;
      }

      toast.error(errorMessage, { id: "sentMagicLinkError", duration: 6000 });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeEmail = (email) => setEmail(email);

  return (
    <main className="container mx-auto max-w-sm">
      <div className="mt-8 p-4 flex flex-col items-center bg-white shadow-custom rounded-[12px] ">
        <MdLockReset size={"48px"} className="color-primary mb-6" />
        <h1 className="text-3xl font-bold mb-8 text-primary">Forgot Password</h1>
        <form onSubmit={handleSubmit} noValidate className="w-full flex flex-col gap-4">
          <Input type="email" name="email" placeholder="Email Address*" isRequired handleFunc={handleChangeEmail} />

          <button
            type="submit"
            className="w-full mt-4 mb-6 bg-primary text-white py-3 rounded-lg font-bold hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom"
          >
            {isLoading ? (
              <div className="flex justify-center">
                <CircularProgress color="#fff" />
              </div>
            ) : (
              "Send Magic Link"
            )}
          </button>
        </form>
      </div>
    </main>
  );
};

export default ForgotPassword;
