import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import debounce from "lodash/debounce";
import toast from "react-hot-toast";
import { GrSearch } from "react-icons/gr";
import { CircularProgress, Input } from "../../UI";
import CustomSelect from "../../UI/CustomSelect";
import DateSelector from "../../UI/DateSelector";
import TimeSelector from "../../UI/TimeSelector";
import TextAreaGigForm from "./TextAreaGigForm";

const AVIATION_STACK_API_URL = "https://api.aviationstack.com/v1/flights";
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes
const TRAVEL_TYPES = [
  { id: "Plane", name: "Plane" },
  { id: "Train", name: "Train" },
  { id: "Car", name: "Car" },
  { id: "Bus", name: "Bus" },
  { id: "Other", name: "Other" },
];

const TravelDetails = ({ gigData, handleChange, handleDateChange, handleTimeChange, handleFileUpload }) => {
  const [attachments, setAttachments] = useState(gigData.attachments?.travel_attachments || []);
  const [flightErrors, setFlightErrors] = useState({ outbound: "", return: "" });
  const [isLoading, setIsLoading] = useState({ outbound: false, return: false });
  const [flightCache, setFlightCache] = useState({});

  const handleAttachmentChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      // 5MB limit
      handleFileUpload(file, "travel_attachments").then((url) => {
        const newAttachments = [...attachments, { name: file.name, url }];
        setAttachments(newAttachments);
        handleChange({
          target: { name: "attachments", value: { ...gigData.attachments, travel_attachments: newAttachments } },
        });
      });
    } else {
      alert("File size should not exceed 5MB");
    }
  };

  useEffect(() => {
    if (flightErrors.outbound) {
      toast.error(flightErrors.outbound, { id: "flightErrors.outbound" });
    }

    if (flightErrors.return) {
      toast.error(flightErrors.return, { id: "flightErrors.return" });
    }
  }, [flightErrors]);

  const fetchFlightDetails = useCallback(
    debounce(async (flightNumber, isOutbound) => {
      const direction = isOutbound ? "outbound" : "return";
      setIsLoading((prev) => ({ ...prev, [direction]: true }));
      setFlightErrors((prev) => ({ ...prev, [direction]: "" }));

      // Check cache first
      const cacheKey = `${flightNumber}-${new Date().toDateString()}`;
      if (flightCache[cacheKey] && new Date() - flightCache[cacheKey].timestamp < CACHE_DURATION) {
        updateFlightDetails(flightCache[cacheKey].data, isOutbound);
        setIsLoading((prev) => ({ ...prev, [direction]: false }));
        return;
      }

      try {
        if (!process.env.REACT_APP_AVIATION_STACK_API_KEY) {
          throw new Error("API key is missing");
        }

        const response = await axios.get(AVIATION_STACK_API_URL, {
          params: {
            access_key: process.env.REACT_APP_AVIATION_STACK_API_KEY,
            flight_iata: flightNumber,
          },
          timeout: 10000, // 10 second timeout
        });

        if (response.data.error) {
          throw new Error(response.data.error.info);
        }

        if (response.data.data && response.data.data.length > 0) {
          const flightData = response.data.data[0];
          // Cache the result
          setFlightCache((prev) => ({
            ...prev,
            [cacheKey]: { data: flightData, timestamp: new Date() },
          }));
          updateFlightDetails(flightData, isOutbound);
        } else {
          setFlightErrors((prev) => ({ ...prev, [direction]: "No flight details found for the given flight number." }));
        }
      } catch (error) {
        console.error("Error fetching flight details:", error);
        if (axios.isAxiosError(error)) {
          if (error.response) {
            console.error("Error response:", error.response.data);
            console.error("Error status:", error.response.status);
            setFlightErrors((prev) => ({
              ...prev,
              [direction]: `API Error: ${error.response.status} - ${error.response.data.message || "Unknown error"}`,
            }));
          } else if (error.request) {
            console.error("Error request:", error.request);
            setFlightErrors((prev) => ({
              ...prev,
              [direction]: "Network error: Unable to reach the server. Please check your internet connection.",
            }));
          } else {
            console.error("Error message:", error.message);
            setFlightErrors((prev) => ({ ...prev, [direction]: `Error: ${error.message}` }));
          }
        } else {
          setFlightErrors((prev) => ({ ...prev, [direction]: `Unexpected error: ${error.message}` }));
        }
      } finally {
        setIsLoading((prev) => ({ ...prev, [direction]: false }));
      }
    }, 1000),
    [flightCache]
  ); // 1 second debounce

  const updateFlightDetails = (flightData, isOutbound) => {
    const prefix = isOutbound ? "outbound" : "return";
    handleChange({ target: { name: `${prefix}_departure_airport`, value: flightData.departure.iata } });
    handleChange({ target: { name: `${prefix}_arrival_airport`, value: flightData.arrival.iata } });

    // Use the exact date and time provided by the API without any adjustments
    const departureDate = new Date(flightData.departure.scheduled);
    const departureDateOnly = new Date(
      departureDate.getUTCFullYear(),
      departureDate.getUTCMonth(),
      departureDate.getUTCDate()
    );
    const departureTimeOnly = new Date(0, 0, 0, departureDate.getUTCHours(), departureDate.getUTCMinutes());

    handleDateChange(`${prefix}_date`, departureDateOnly);
    handleTimeChange(`${prefix}_time`, departureTimeOnly);
  };

  const handleFlightNumberChange = (e, isOutbound) => {
    const flightNumber = e.target.value;
    const field = isOutbound ? "outbound_plane_number" : "return_plane_number";
    handleChange({ target: { name: field, value: flightNumber } });
  };

  const handleSearchClick = (isOutbound) => {
    const direction = isOutbound ? "outbound" : "return";
    const flightNumber = gigData[`${direction}_plane_number`];
    if (flightNumber) {
      fetchFlightDetails(flightNumber, isOutbound);
    } else {
      setFlightErrors((prev) => ({ ...prev, [direction]: "Please enter a flight number before searching." }));
    }
  };

  const renderPlaneFields = () => (
    <>
      <div className="col-span-12 sm:col-span-3">
        <div
          className={`relative rounded-lg border-2 max-h-14 group ${!!flightErrors.outbound ? "border-red-500" : "border-gray-300 focus-within:border-primary group-active:border-primary"}`}
        >
          <input
            type={"text"}
            name={"outbound_plane_number"}
            value={gigData.outbound_plane_number || ""}
            onChange={(e) => handleFlightNumberChange(e, true)}
            id={"outbound_plane_number"}
            className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer text-primary`}
            placeholder=" "
          />

          {isLoading.outbound ? (
            <CircularProgress size={5} className={"absolute right-1 size-10 top-5"} />
          ) : (
            <GrSearch
              onClick={() => {
                if (isLoading.outbound) return;
                handleSearchClick(true);
              }}
              className="absolute right-1 size-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:bg-gray-100 cursor-pointer rounded-full transition-all ease-in-out p-2"
            />
          )}
          <label
            htmlFor={"outbound_plane_number"}
            className={`absolute text-md text-gray-500 
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:bg-white peer-focus:-translate-y-4 peer-focus:bg-green whitespace-nowrap overflow-hidden text-ellipsis peer-focus:max-w-none max-w-full ${!!flightErrors.outbound ? "text-red-500" : "text-gray-500 "}`}
          >
            {"Outbound Flight Number"}
          </label>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Confirmation Number"
          name="outbound_confirmation_number"
          value={gigData.outbound_confirmation_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <DateSelector
          selectedDate={gigData.outbound_date}
          handleChangeFunc={handleDateChange}
          name={"outbound_date"}
          placeholder={"Outbound Date"}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.outbound_time}
          handleChangeFunc={handleTimeChange}
          name={"outbound_time"}
          placeholder="Outbound Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Departure Airport"
          name="outbound_departure_airport"
          value={gigData.outbound_departure_airport || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Arrival Airport"
          name="outbound_arrival_airport"
          value={gigData.outbound_arrival_airport || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Seat(s)"
          name="outbound_seat"
          value={gigData.outbound_seat || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Ticket Number"
          name="outbound_ticket_number"
          value={gigData.outbound_ticket_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <div
          className={`relative rounded-lg border-2 max-h-14 group ${!!flightErrors.return ? "border-red-500" : "border-gray-300 focus-within:border-primary group-active:border-primary"}`}
        >
          <input
            type={"text"}
            name={"return_plane_number"}
            value={gigData.return_plane_number || ""}
            onChange={(e) => handleFlightNumberChange(e, false)}
            id="return_plane_number"
            className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer text-primary`}
            placeholder=" "
          />

          {isLoading.return ? (
            <CircularProgress size={5} className={"absolute right-1 size-10 top-5"} />
          ) : (
            <GrSearch
              onClick={() => {
                if (isLoading.return) return;
                handleSearchClick(true);
              }}
              className="absolute right-1 size-10 top-1/2 transform -translate-y-1/2 text-gray-400 hover:bg-gray-100 cursor-pointer rounded-full transition-all ease-in-out p-2"
            />
          )}
          <label
            htmlFor="return_plane_number"
            className={`absolute text-md text-gray-500 
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:bg-white peer-focus:-translate-y-4 peer-focus:bg-green whitespace-nowrap overflow-hidden text-ellipsis peer-focus:max-w-none max-w-full ${!!flightErrors.return ? "text-red-500" : "text-gray-500 "}`}
          >
            {"Return Flight Number"}
          </label>
        </div>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Confirmation Number"
          name="return_confirmation_number"
          value={gigData.return_confirmation_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <DateSelector
          selectedDate={gigData.return_date}
          handleChangeFunc={handleDateChange}
          name={"return_date"}
          placeholder={"Return Date"}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.return_time}
          handleChangeFunc={handleTimeChange}
          name={"return_time"}
          placeholder="Return Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Departure Airport"
          name="return_departure_airport"
          value={gigData.return_departure_airport || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Arrival Airport"
          name="return_arrival_airport"
          value={gigData.return_arrival_airport || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Seat(s)"
          name="return_seat"
          value={gigData.return_seat || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Ticket Number"
          name="return_ticket_number"
          value={gigData.return_ticket_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
    </>
  );

  const renderTrainFields = () => (
    <>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Train Number"
          name="outbound_train_number"
          value={gigData.outbound_train_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Train Booking Reference"
          name="outbound_train_booking_reference"
          value={gigData.outbound_train_booking_reference || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <DateSelector
          selectedDate={gigData.outbound_date}
          handleChangeFunc={handleDateChange}
          name={"outbound_date"}
          placeholder={"Outbound Date"}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.outbound_time}
          handleChangeFunc={handleTimeChange}
          name={"outbound_time"}
          placeholder="Outbound Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Departure Train Station"
          name="outbound_departure_train_station"
          value={gigData.outbound_departure_train_station || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Arrival Train Station"
          name="outbound_arrival_train_station"
          value={gigData.outbound_arrival_train_station || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Outbound Seat(s)"
          name="outbound_seat"
          value={gigData.outbound_seat || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Train Number"
          name="return_train_number"
          value={gigData.return_train_number || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Train Booking Reference"
          name="return_train_booking_reference"
          value={gigData.return_train_booking_reference || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <DateSelector
          selectedDate={gigData.return_date}
          handleChangeFunc={handleDateChange}
          name={"return_date"}
          placeholder={"Return Date"}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.return_time}
          handleChangeFunc={handleTimeChange}
          name={"return_time"}
          placeholder="Return Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Departure Train Station"
          name="return_departure_train_station"
          value={gigData.return_departure_train_station || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Arrival Train Station"
          name="return_arrival_train_station"
          value={gigData.return_arrival_train_station || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder="Return Seat(s)"
          name="return_seat"
          value={gigData.return_seat || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
    </>
  );

  return (
    <>
      <div className="col-span-12 sm:col-span-6">
        <CustomSelect
          name={"travel_mode"}
          placeholder={"Travel Mode"}
          selectedValue={gigData.travel_mode}
          handleChangeFunc={handleChange}
          options={TRAVEL_TYPES}
        />
      </div>

      {gigData.travel_mode === "Plane" && renderPlaneFields()}
      {gigData.travel_mode === "Train" && renderTrainFields()}

      <div className="col-span-12">
        <TextAreaGigForm
          handleFunc={handleChange}
          name="transport_notes"
          value={gigData.transport_notes || ""}
          placeholder={"Travel Notes"}
          rows={4}
        />
      </div>
      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Travel Attachments</h6>
        {attachments.map((attachment, index) => (
          <h6 className="text-secondaryText font-medium text-md" key={index}>
            {attachment.name}
          </h6>
        ))}
        {attachments.length < 8 && (
          <div className="mt-2">
            <label
              className={`bg-white border-2 border-primary text-primary text-sm py-2 px-4 rounded-[20px] font-medium transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom cursor-pointer `}
            >
              Upload File <input type="file" hidden onChange={handleAttachmentChange} />
            </label>
          </div>
        )}
        {attachments.length >= 8 && <h6 className="text-red-600 font-medium">Maximum of 8 attachments reached</h6>}
      </div>
    </>
  );
};

export default TravelDetails;
