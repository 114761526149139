import React, { useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { IoMenuSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import CoolCoolEmoji from "./../../cool-cool-emoji.gif";
import IconButton from "./IconButton";

const Header = ({ session, handleResetAdminUser, navigationItems }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const handleClickOutsideDrawer = useClickAway(closeDrawer);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    closeDrawer();
    handleResetAdminUser();
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setDrawerOpen(open);
  };

  const drawer = (
    <div className="w-64" role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
      <ul>
        {navigationItems.map((item) => (
          <li key={item.text}>
            <Link to={item.path} className="block px-4 py-2 text-lg text-primary hover:bg-gray-100">
              {item.text}
            </Link>
          </li>
        ))}
        <li>
          <button
            className="block w-full text-start px-4 py-2 text-lg text-primary hover:bg-gray-100 md:hidden"
            onClick={handleSignOut}
          >
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  );

  return (
    <header className="sticky top-0 bg-white shadow-md z-10">
      <div className="flex items-center justify-between px-4 py-2">
        {session && (
          <IconButton className={"mr-1 block lg:hidden"} handleFunc={openDrawer}>
            <IoMenuSharp className="text-primary size-8" />
          </IconButton>
        )}

        <div className="flex flex-grow items-center">
          <Link to="/" style={{ textDecoration: "none", color: "inherit" }}>
            <div className="flex items-center gap-3">
              <img
                src={CoolCoolEmoji}
                alt="Cool Cool Emoji"
                style={{
                  height: "35px",
                }}
              />
              <h6 className="text-xl text-primary led tracking-wider font-sans">GIGDADDY</h6>
            </div>
          </Link>
        </div>
        {session ? (
          <>
            <div className="block lg:hidden">
              {drawerOpen && <div className="fixed top-0 left-0 h-full w-full bg-gray-500 bg-opacity-70 z-40" />}

              <div
                className={`fixed top-0 left-0 h-full w-full shadow-lg transform transition-transform duration-300 ${
                  drawerOpen ? "translate-x-0" : "-translate-x-full"
                } z-50`}
              >
                <div ref={handleClickOutsideDrawer} className="bg-white h-full w-64 pt-4">
                  {drawer}
                </div>
              </div>
            </div>

            <div className="hidden lg:block">
              {navigationItems.map((item) => (
                <Link key={item.text} to={item.path}>
                  <button className="text-md bg-white text-primary py-3 px-6 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom">
                    {item.text}
                  </button>
                </Link>
              ))}
            </div>
            <button
              className="text-md bg-white text-primary py-3 px-6 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom hidden md:block"
              onClick={handleSignOut}
            >
              Sign Out
            </button>
          </>
        ) : (
          <>
            <Link to={"/"}>
              <button className="text-md bg-white text-primary py-3 px-6 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom">
                Home
              </button>
            </Link>
            <Link to={"/login"}>
              <button className="text-md bg-white text-primary py-3 px-6 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom">
                Login
              </button>
            </Link>
          </>
        )}
      </div>
    </header>
  );
};

export default Header;
