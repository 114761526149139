import React from "react";
import { Input } from "../../UI";
import CustomSelect from "../../UI/CustomSelect";
import TimeSelector from "../../UI/TimeSelector";
import TextAreaGigForm from "./TextAreaGigForm";

const PERFORMANCE_TYPES = [
  { id: "DJ", name: "DJ" },
  { id: "LIVE", name: "Live" },
  { id: "HYBRID", name: "Hybrid" },
];

const EventDetails = ({ gigData, handleChange, handleTimeChange }) => {
  return (
    <>
      <div className="col-span-12 sm:col-span-6">
        <CustomSelect
          name={"performance_type"}
          placeholder={"Performance Type"}
          selectedValue={gigData.performance_type}
          handleChangeFunc={handleChange}
          options={PERFORMANCE_TYPES}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.soundcheck_time}
          handleChangeFunc={handleTimeChange}
          name={"soundcheck_time"}
          placeholder="Soundcheck Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.show_time}
          handleChangeFunc={handleTimeChange}
          name={"show_time"}
          placeholder="Show Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.doors_open_time}
          handleChangeFunc={handleTimeChange}
          name={"doors_open_time"}
          placeholder="Doors Open Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <TimeSelector
          selectedTime={gigData.curfew_time}
          handleChangeFunc={handleTimeChange}
          name={"curfew_time"}
          placeholder="Curfew Time"
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          type={"number"}
          placeholder="Duration (Hours)"
          name="duration_hours"
          value={gigData.duration_hours}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          type={"number"}
          placeholder="Duration (Minutes)"
          name="duration_minutes"
          value={gigData.duration_minutes}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Input
          placeholder="Stage Name"
          name="stage_name"
          value={gigData.stage_name}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12">
        <TextAreaGigForm placeholder="Lineup" rows={3} name="lineup" value={gigData.lineup} handleFunc={handleChange} />
      </div>
      <div className="col-span-12">
        <TextAreaGigForm
          placeholder="Number and Type of Guest List Spots"
          rows={3}
          name="guest_list"
          value={gigData.guest_list}
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12">
        <TextAreaGigForm
          placeholder="Other Info"
          rows={3}
          name="other_info"
          value={gigData.other_info}
          handleFunc={handleChange}
        />
      </div>
    </>
  );
};

export default EventDetails;
