import React, { useEffect, useMemo, useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import * as DialogRadix from "@radix-ui/react-dialog";
import { FiEdit, FiTrash2 } from "react-icons/fi";
import { TbCalendarEvent } from "react-icons/tb";
import { Card, IconButton } from "./../UI";

const GigCalendar = ({ gigs, onGigClick, onEditGig, onDeleteGig }) => {
  const [value, onChange] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [filteredGigs, setFilteredGigs] = useState([]);
  const [isDeleteGig, setIsDeleteGig] = useState(false);

  // Sort all gigs by artist name
  const sortedGigs = useMemo(() => {
    return [...gigs].sort((a, b) => {
      const artistA = a.artists?.name || "Unknown Artist";
      const artistB = b.artists?.name || "Unknown Artist";
      return artistA.localeCompare(artistB);
    });
  }, [gigs]);

  // Helper function to format date to YYYY-MM-DD
  const formatDate = (date) => {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  };

  useEffect(() => {
    if (selectedDate) {
      const formattedSelectedDate = formatDate(selectedDate);
      const filtered = sortedGigs.filter((gig) => gig.date === formattedSelectedDate);
      setFilteredGigs(filtered);
    } else {
      setFilteredGigs([]);
    }
  }, [selectedDate, sortedGigs]);

  const tileClassName = ({ date, view }) => {
    if (view === "month") {
      const formattedDate = formatDate(date);
      const hasGig = sortedGigs.some((gig) => gig.date === formattedDate);
      return hasGig ? "hasGig" : "";
    }
  };

  const handleDateClick = (value) => {
    onChange(value);
    setSelectedDate(value);
  };

  const handleGigClick = (gig) => {
    if (onGigClick) {
      onGigClick(gig);
    }
  };

  const renderGigList = () => {
    if (!selectedDate) return null;

    return (
      <div className="mt-6">
        <h6 className="font-bold text-xl text-primary mb-4">Gigs for {selectedDate.toDateString()}</h6>
        {filteredGigs.length === 0 ? (
          <p className="text-sm italic text-primary">No gigs scheduled for this date.</p>
        ) : (
          <div className="flex flex-col gap-3">
            {filteredGigs.map((gig) => (
              <React.Fragment key={gig.id}>
                <Card onClick={() => handleGigClick(gig)} isCalendarStyle>
                  <div className="flex justify-between items-center">
                    <div>
                      <h6 className="font-bold text-secondary text-md">
                        {gig.artists?.name || "Unknown Artist"} - {gig.event_name || "Unnamed Event"}
                      </h6>
                      <span className="text-secondaryText text-sm">
                        {gig.venue_name || "Unknown Venue"}, {gig.city || "Unknown City"}
                      </span>
                      <p className="mt-2 text-sm text-primary flex items-center">
                        <TbCalendarEvent className="size-5 mr-1 text-primary" />
                        Show time: {gig.show_time || "Not specified"}
                      </p>
                    </div>

                    <div className="flex items-center sm:space-x-2 flex-col sm:flex-row justify-center">
                      <IconButton handleFunc={() => onEditGig(gig)}>
                        <FiEdit className="size-5" />
                      </IconButton>
                      <IconButton handleFunc={() => setIsDeleteGig(gig)}>
                        <FiTrash2 className="size-5 text-red-600" />
                      </IconButton>
                    </div>
                  </div>
                </Card>
                <DialogRadix.Root open={isDeleteGig} onOpenChange={setIsDeleteGig}>
                  <DialogRadix.Portal>
                    <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
                    <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[575px] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
                      <DialogRadix.Title className="mb-6 text-primary text-xl font-bold">
                        {"Delete Gig?"}
                      </DialogRadix.Title>
                      <DialogRadix.Description className="mb-4 text-primary text-md">
                        Are you sure you want to delete this gig?
                      </DialogRadix.Description>

                      <div className="flex mt-6 gap-5 justify-end">
                        <DialogRadix.Close asChild>
                          <button className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3">
                            Cancel
                          </button>
                        </DialogRadix.Close>

                        <button
                          onClick={() => {
                            setIsDeleteGig(false);
                            onDeleteGig(gig);
                          }}
                          className="text-sm bg-white text-red-600 border border-red-600 p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3"
                        >
                          Delete
                        </button>
                      </div>
                    </DialogRadix.Content>
                  </DialogRadix.Portal>
                </DialogRadix.Root>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="calendar-container">
      <h4 className="text-3xl mb-3 font-medium text-primary">Calendar</h4>
      <div className="bg-white shadow-custom rounded-lg w-full mb-8 overflow-hidden">
        <Calendar onChange={handleDateClick} value={value} tileClassName={tileClassName} />
      </div>
      {renderGigList()}
    </div>
  );
};

export default GigCalendar;
