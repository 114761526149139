import React from "react";

const Footer = () => {
  return (
    <footer className={`py-6 px-2 mt-auto bg-gray-200`}>
      <div className="max-w-screen-lg mx-auto">
        <p className={`text-center text-xs sm:text-sm text-gray-600`}>
          Copyright © {new Date().getFullYear()} GIGDADDY
        </p>
      </div>
    </footer>
  );
};

export default Footer;
