import icalGenerator from 'ical-generator';
import ICAL from 'ical.js';
import { supabase } from '../supabaseClient';

export const createICalendar = async (artistId, artistName) => {
  try {
    console.log('Creating iCalendar for artist:', artistId, artistName);
    
    // Create a new calendar with the artist's name
    const calendar = icalGenerator({ name: `${artistName}'s GigDaddy Calendar` });

    // Generate the iCalendar file content
    const icsContent = calendar.toString();

    // Generate a filename for the artist's calendar
    const fileName = `${artistId}.ics`;

    // Upload the iCalendar file to Supabase storage
    const { data, error: uploadError } = await supabase
      .storage
      .from('calendars')
      .upload(fileName, icsContent, {
        contentType: 'text/calendar',
        upsert: true
      });

    if (uploadError) throw uploadError;

    // Get the public URL of the uploaded file
    const { data: { publicUrl }, error: urlError } = supabase
      .storage
      .from('calendars')
      .getPublicUrl(fileName);

    if (urlError) throw urlError;

    console.log('iCalendar created successfully:', publicUrl);
    return publicUrl;
  } catch (error) {
    console.error('Error creating iCalendar:', error);
    throw error;
  }
};

export const addGigToCalendar = async (artistId, gig) => {
  console.log('addGigToCalendar called for artist:', artistId, 'gig:', gig.id);
  try {
    const calendar = await fetchCalendar(artistId);
    
    calendar.createEvent({
      id: gig.id,
      start: new Date(gig.date),
      end: new Date(new Date(gig.date).setDate(new Date(gig.date).getDate() + 1)),
      summary: `${gig.event_name} at ${gig.venue_name}`,
      location: `${gig.venue_address}, ${gig.city}, ${gig.state}, ${gig.country}`,
      description: formatGigDetails(gig),
      allDay: true
    });

    await updateCalendarFile(artistId, calendar);
  } catch (error) {
    console.error('Error adding gig to calendar:', error);
    throw error;
  }
};

export const updateGigInCalendar = async (artistId, gig) => {
  console.log('updateGigInCalendar called for artist:', artistId, 'gig:', gig.id);
  try {
    const calendar = await fetchCalendar(artistId);

    // Remove the existing event for this gig
    const updatedEvents = calendar.events().filter(event => event.id() !== gig.id);
    
    // Clear all events and add the filtered events back
    calendar.clear();
    updatedEvents.forEach(event => calendar.createEvent(event));

    // Add the updated gig
    calendar.createEvent({
      id: gig.id,
      start: new Date(gig.date),
      end: new Date(new Date(gig.date).setDate(new Date(gig.date).getDate() + 1)),
      summary: `${gig.event_name} at ${gig.venue_name}`,
      location: `${gig.venue_address}, ${gig.city}, ${gig.state}, ${gig.country}`,
      description: formatGigDetails(gig),
      allDay: true
    });

    await updateCalendarFile(artistId, calendar);
  } catch (error) {
    console.error('Error updating gig in calendar:', error);
    throw error;
  }
};

export const deleteGigFromCalendar = async (artistId, gigId) => {
  try {
    const calendar = await fetchCalendar(artistId);

    // Remove the event for this gig
    const updatedEvents = calendar.events().filter(event => event.id() !== gigId);
    
    // Clear all events and add the filtered events back
    calendar.clear();
    updatedEvents.forEach(event => calendar.createEvent(event));

    await updateCalendarFile(artistId, calendar);
  } catch (error) {
    console.error('Error deleting gig from calendar:', error);
    throw error;
  }
};

const fetchCalendar = async (artistId) => {
  const fileName = `${artistId}.ics`;
  const { data, error } = await supabase
    .storage
    .from('calendars')
    .download(fileName);

  if (error) {
    console.error('Error fetching calendar:', error);
    throw error;
  }

  const icsContent = await data.text();
  const jcalData = ICAL.parse(icsContent);
  const vcalendar = new ICAL.Component(jcalData);
  const vevents = vcalendar.getAllSubcomponents('vevent');

  // Fetch the artist's name
  const { data: artistData, error: artistError } = await supabase
    .from('artists')
    .select('name')
    .eq('id', artistId)
    .single();

  if (artistError) {
    console.error('Error fetching artist name:', artistError);
    throw artistError;
  }

  const calendar = icalGenerator({ name: `${artistData.name}'s GigDaddy Calendar` });

  vevents.forEach(vevent => {
    const event = new ICAL.Event(vevent);
    calendar.createEvent({
      id: event.uid,
      start: event.startDate.toJSDate(),
      end: new Date(event.startDate.toJSDate().setDate(event.startDate.toJSDate().getDate() + 1)),
      summary: event.summary,
      description: event.description,
      location: event.location,
      allDay: true
    });
  });

  return calendar;
};

const updateCalendarFile = async (artistId, calendar) => {
  const icsContent = calendar.toString();
  const fileName = `${artistId}.ics`;

  const { error } = await supabase
    .storage
    .from('calendars')
    .update(fileName, icsContent, {
      contentType: 'text/calendar',
      upsert: true
    });

  if (error) throw error;
};

const formatGigDetails = (gig) => {
  let details = '';

  // Basic Information
  details += 'Basic Information\n';
  if (gig.event_name) details += `Event: ${gig.event_name}\n`;
  if (gig.venue_name) details += `Venue: ${gig.venue_name}\n`;
  if (gig.venue_address) details += `Address: ${gig.venue_address}, ${gig.city}, ${gig.state}, ${gig.country}\n`;
  if (gig.performance_type) details += `Performance Type: ${gig.performance_type}\n`;
  if (gig.stage_name) details += `Stage Name: ${gig.stage_name}\n`;

  // Schedule
  let scheduleDetails = '';
  if (gig.doors_open_time) scheduleDetails += `- Doors Open: ${gig.doors_open_time}\n`;
  if (gig.soundcheck_time) scheduleDetails += `- Soundcheck: ${gig.soundcheck_time}\n`;
  if (gig.show_time) scheduleDetails += `- Show Time: ${gig.show_time}\n`;
  if (gig.curfew_time) scheduleDetails += `- Curfew: ${gig.curfew_time}\n`;

  if (scheduleDetails) {
    details += '\nSchedule:\n' + scheduleDetails;
  } else {
    details += '\nSchedule: No Information Added\n';
  }

  if (gig.duration_hours || gig.duration_minutes) {
    details += `\nDuration: ${gig.duration_hours || 0}h ${gig.duration_minutes || 0}m\n`;
  }

  // Travel Details
  let travelDetails = '';
  if (gig.travel_mode) travelDetails += `Mode: ${gig.travel_mode}\n`;
  if (gig.outbound_date && gig.outbound_time) {
    travelDetails += `Outbound: ${gig.outbound_date} at ${gig.outbound_time}\n`;
  }
  if (gig.return_date && gig.return_time) {
    travelDetails += `Return: ${gig.return_date} at ${gig.return_time}\n`;
  }

  // Add other travel details
  const travelFields = [
    'outbound_plane_number', 'outbound_train_number', 'outbound_confirmation_number',
    'outbound_train_booking_reference', 'outbound_departure_airport', 'outbound_arrival_airport',
    'outbound_departure_train_station', 'outbound_arrival_train_station', 'outbound_seat',
    'outbound_ticket_number', 'return_plane_number', 'return_train_number',
    'return_confirmation_number', 'return_train_booking_reference', 'return_departure_airport',
    'return_arrival_airport', 'return_departure_train_station', 'return_arrival_train_station',
    'return_seat', 'return_ticket_number', 'transport_notes'
  ];

  travelFields.forEach(field => {
    if (gig[field]) {
      travelDetails += `${field.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())}: ${gig[field]}\n`;
    }
  });

  if (travelDetails) {
    details += '\nTravel Details\n' + travelDetails;
  } else {
    details += '\nTravel Details: No Information Added\n';
  }

  // Accommodation
  let accommodationDetails = '';
  if (gig.hotel_name) accommodationDetails += `${gig.hotel_name}`;
  if (gig.hotel_address) accommodationDetails += `, ${gig.hotel_address}\n`;
  if (gig.hotel_phone) accommodationDetails += `Phone: ${gig.hotel_phone}\n`;
  if (gig.hotel_website) accommodationDetails += `Website: ${gig.hotel_website}\n`;
  if (gig.hotel_notes) accommodationDetails += `Notes: ${gig.hotel_notes}\n`;

  if (accommodationDetails) {
    details += '\nAccommodation\n' + accommodationDetails;
  } else {
    details += '\nAccommodation: No Information Added\n';
  }

  // Contacts
  let contactDetails = '';
  if (gig.event_contact1_name) {
    contactDetails += `1. ${gig.event_contact1_name}: ${gig.event_contact1_phone}`;
    if (gig.event_contact1_role) contactDetails += ` (${gig.event_contact1_role})`;
    contactDetails += '\n';
  }
  if (gig.event_contact2_name) {
    contactDetails += `2. ${gig.event_contact2_name}: ${gig.event_contact2_phone}`;
    if (gig.event_contact2_role) contactDetails += ` (${gig.event_contact2_role})`;
    contactDetails += '\n';
  }

  if (contactDetails) {
    details += '\nContacts\n' + contactDetails;
  } else {
    details += '\nContacts: No Information Added\n';
  }

  // Additional Info
  let additionalInfo = '';
  if (gig.lineup) additionalInfo += `Lineup: ${gig.lineup}\n`;
  if (gig.guest_list) additionalInfo += `Guest List: ${gig.guest_list}\n`;
  if (gig.other_info) additionalInfo += `Other Info: ${gig.other_info}\n`;

  if (additionalInfo) {
    details += '\nAdditional Info\n' + additionalInfo;
  } else {
    details += '\nAdditional Info: No Information Added\n';
  }

  // Transportation
  let transportationDetails = '';
  if (gig.airport_pickup_contact_name) {
    transportationDetails += `Airport/Train Station Pickup:\n`;
    transportationDetails += `- Driver Name: ${gig.airport_pickup_contact_name}\n`;
    if (gig.airport_pickup_contact_phone) transportationDetails += `- Driver Phone: ${gig.airport_pickup_contact_phone}\n`;
    if (gig.airport_pickup_instructions) transportationDetails += `- Other Info: ${gig.airport_pickup_instructions}\n`;
  }
  if (gig.venue_transportation_pickup_driver_name) {
    transportationDetails += `Venue Pickup:\n`;
    transportationDetails += `- Driver Name: ${gig.venue_transportation_pickup_driver_name}\n`;
    if (gig.venue_transportation_pickup_driver_phone) transportationDetails += `- Driver Phone: ${gig.venue_transportation_pickup_driver_phone}\n`;
    if (gig.venue_transportation_pickup_time) transportationDetails += `- Pickup Time: ${gig.venue_transportation_pickup_time}\n`;
    if (gig.venue_transportation_pickup_driver_notes) transportationDetails += `- Notes: ${gig.venue_transportation_pickup_driver_notes}\n`;
  }
  if (gig.venue_transportation_return_driver_name) {
    transportationDetails += `Venue Return:\n`;
    transportationDetails += `- Driver Name: ${gig.venue_transportation_return_driver_name}\n`;
    if (gig.venue_transportation_return_driver_phone) transportationDetails += `- Driver Phone: ${gig.venue_transportation_return_driver_phone}\n`;
    if (gig.venue_transportation_return_time) transportationDetails += `- Return Time: ${gig.venue_transportation_return_time}\n`;
    if (gig.venue_transportation_return_driver_notes) transportationDetails += `- Notes: ${gig.venue_transportation_return_driver_notes}\n`;
  }
  if (gig.airport_return_contact_name) {
    transportationDetails += `Airport/Train Station Return:\n`;
    transportationDetails += `- Driver Name: ${gig.airport_return_contact_name}\n`;
    if (gig.airport_return_contact_phone) transportationDetails += `- Driver Phone: ${gig.airport_return_contact_phone}\n`;
    if (gig.airport_return_pickup_time) transportationDetails += `- Pickup Time: ${gig.airport_return_pickup_time}\n`;
    if (gig.airport_return_instructions) transportationDetails += `- Other Info: ${gig.airport_return_instructions}\n`;
  }
  if (gig.transportation_notes) transportationDetails += `Transportation Notes: ${gig.transportation_notes}\n`;

  if (transportationDetails) {
    details += '\nTransportation\n' + transportationDetails;
  } else {
    details += '\nTransportation: No Information Added\n';
  }

  // Attachments
  let attachmentDetails = '';
  
  if (gig.attachments?.travel_attachments && gig.attachments.travel_attachments.length > 0) {
    attachmentDetails += 'Travel Attachments:\n';
    gig.attachments.travel_attachments.forEach((attachment, index) => {
      attachmentDetails += `${index + 1}. ${attachment.name}: ${attachment.url}\n`;
    });
  }

  if (gig.attachments?.general_attachments && gig.attachments.general_attachments.length > 0) {
    attachmentDetails += 'General Attachments:\n';
    gig.attachments.general_attachments.forEach((attachment, index) => {
      attachmentDetails += `${index + 1}. ${attachment.name}: ${attachment.url}\n`;
    });
  }

  if (attachmentDetails) {
    details += '\nAttachments\n' + attachmentDetails;
  }

  return details;
};

export { formatGigDetails };