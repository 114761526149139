import toast from "react-hot-toast";
import { supabase } from "../../supabaseClient";

export const handleCopyCalendarUrl = (calenderUrl) => {
  navigator.clipboard.writeText(calenderUrl);
  toast.success("Calendar URL copied to clipboard", { id: "successfullyCopyCalendarUrl" });
};

export const handleCopyArtistPageUrl = (id) => {
  const artistPageUrl = `${window.location.origin}/artist/${id}`;
  navigator.clipboard.writeText(artistPageUrl);
  toast.success("Artist page URL copied to clipboard", { id: "successfullyCopyArtistUrl" });
};
