import React from "react";
import { Navigate, Route, Routes as RouterDomRoutes } from "react-router-dom";
import {
  AddArtist,
  AdminDashboard,
  ArtistManagement,
  ArtistProfile,
  ArtistPublicPage,
  Auth,
  EditGig,
  ForgotPassword,
  GigDetails,
  GoogleAuthCallback,
  GoogleMapsLoader,
  HomePage,
  LandingPage,
  PasswordReset,
  UserProfile,
} from "../components";
import GigForm from "../components/GigForm/GigForm";

const Routes = ({ session, isAdminUser }) => {
  return (
    <RouterDomRoutes>
      <Route path="/login" element={!session ? <Auth /> : <Navigate to="/" />} />
      <Route path="/artists" element={session ? <ArtistManagement /> : <Navigate to="/login" />} />
      <Route path="/artists/:id" element={session ? <ArtistProfile /> : <Navigate to="/login" />} />
      <Route path="/artists/add" element={session ? <AddArtist /> : <Navigate to="/login" />} />
      <Route
        path="/gigs/new"
        element={
          session ? (
            <GoogleMapsLoader>
              <GigForm isAdmin={isAdminUser} />
            </GoogleMapsLoader>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/gigs/:id" element={<GigDetails />} />
      <Route
        path="/edit-gig/:id"
        element={
          session ? (
            <GoogleMapsLoader>
              <EditGig isAdmin={isAdminUser} />
            </GoogleMapsLoader>
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route path="/" element={session ? <HomePage /> : <LandingPage />} />
      <Route path="/reset-password" element={<PasswordReset />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/auth/google/callback" element={<GoogleAuthCallback />} />
      <Route path="/profile" element={session ? <UserProfile /> : <Navigate to="/login" />} />
      <Route path="/artist/:id" element={<ArtistPublicPage />} />
      <Route path="/admin" element={session && isAdminUser ? <AdminDashboard /> : <Navigate to="/" />} />
    </RouterDomRoutes>
  );
};

export default Routes;
