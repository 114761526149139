import React from "react";

const TextAreaGigForm = ({ rows, name, placeholder, value, handleFunc }) => {
  return (
    <div
      className={`relative rounded-lg border-2 border-gray-300 group focus-within:border-primary group-active:border-primary`}
    >
      <textarea
        name={name}
        value={value}
        onChange={handleFunc}
        id={name}
        className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer
        text-primary`}
        placeholder=" "
        rows={rows}
      />
      <label
        htmlFor={name}
        className={`absolute text-md text-gray-500
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-1 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/4
      peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 bg-white`}
      >
        {placeholder}
      </label>
    </div>
  );
};

export default TextAreaGigForm;
