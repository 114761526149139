import React, { useCallback, useRef, useState } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { createICalendar } from "../../utils/icalendarUtils";
import { logError } from "../../utils/logger";
import { CircularProgress, Input } from "./../UI";

const MAX_WIDTH = 800;
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB

const AddArtist = () => {
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [previewUrl, setPreviewUrl] = useState("");
  const [isLoadingUploadArtist, setIsLoadingUploadArtist] = useState(false);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast.error("File size should not exceed 2MB", { id: "sizeFileError" });
        return;
      }

      setAvatar(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  }, []);

  const resizeAndCompressImage = useCallback((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          let width = img.width;
          let height = img.height;

          if (width > MAX_WIDTH) {
            height = Math.round((height * MAX_WIDTH) / width);
            width = MAX_WIDTH;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              resolve(new File([blob], file.name, { type: "image/jpeg", lastModified: Date.now() }));
            },
            "image/jpeg",
            0.7
          );
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!name.trim()) {
      toast.error("Please enter an artist name.", { id: "errorArtistName" });
      return;
    }

    setIsLoadingUploadArtist(true);

    toast("Adding artist...", {
      icon: "ℹ️",
      id: "addingArtist",
    });

    try {
      const {
        data: { user },
        error: userError,
      } = await supabase.auth.getUser();

      if (userError) {
        logError(userError);
        console.error("Error getting user:", userError);
        toast.error("Error getting user: " + userError.message, { id: "errorGetUser" });
        return;
      }

      if (!user) {
        const error = new Error("No user found");
        logError(error);
        console.error("No user found");
        toast.error("You must be logged in to add an artist.", { id: "errorUserLogin" });
        return;
      }

      let avatarUrl = null;
      if (avatar) {
        try {
          const processedAvatar = await resizeAndCompressImage(avatar);
          const fileExt = processedAvatar.name.split(".").pop();
          const fileName = `${Math.random()}.${fileExt}`;
          const { error } = await supabase.storage.from("artist-images").upload(fileName, processedAvatar);

          if (error) {
            logError(error);
            console.error("Error uploading avatar:", error);
            throw error;
          }
          avatarUrl = fileName;
        } catch (avatarError) {
          logError(avatarError);
          console.error("Error processing or uploading avatar:", avatarError);
          throw avatarError;
        }
      }

      console.log("Attempting to insert artist:", { name, user_id: user.id, avatar_url: avatarUrl });

      const { data: artistData, error: artistError } = await supabase
        .from("artists")
        .insert([{ name, user_id: user.id, avatar_url: avatarUrl }])
        .select()
        .single();

      if (artistError) {
        logError(artistError);
        console.error("Error inserting artist:", artistError);
        throw artistError;
      }

      console.log("Artist data after insert:", artistData);

      if (!artistData) {
        const error = new Error("Failed to create artist: No data returned from insert operation");
        logError(error);
        console.error("Artist data is null after insert");
        throw error;
      }

      if (!artistData.id) {
        const error = new Error("Failed to create artist: No id returned from insert operation");
        logError(error);
        console.error("Artist data is missing id after insert");
        throw error;
      }

      let calendarUrl = null;
      try {
        calendarUrl = await createICalendar(artistData.id, name);
        console.log("Calendar URL created:", calendarUrl);
      } catch (calendarError) {
        logError(calendarError);
        console.error("Error creating iCalendar:", calendarError);
        calendarUrl = `https://error-creating-calendar.com/${artistData.id}.ics`;
      }

      const { data: updatedArtist, error: updateError } = await supabase
        .from("artists")
        .update({ calendar_url: calendarUrl })
        .eq("id", artistData.id)
        .select()
        .single();

      if (updateError) {
        logError(updateError);
        console.error("Error updating artist with calendar URL:", updateError);
        throw updateError;
      } else {
        console.log("Artist updated with calendar URL:", updatedArtist);
      }
      toast.remove();
      toast.success("Artist added successfully!", { id: "artistAdded" });

      setTimeout(() => navigate("/artists"), 2000);
    } catch (error) {
      logError(error);
      console.error("Error in handleSubmit:", error);
      toast.error("Error adding artist: " + error.message, { id: "artistAddedError" });
    } finally {
      setIsLoadingUploadArtist(false);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleChangeName = (name) => setName(name);

  return (
    <div className="flex flex-col items-center mt-16">
      <div className="bg-white shadow-custom rounded-lg p-8 max-w-md w-full mb-8">
        <h4 className="text-2xl font-medium">Add New Artist</h4>
        <form onSubmit={handleSubmit} className="mt-3 flex flex-col gap-2">
          <Input
            isRequired
            handleFunc={handleChangeName}
            value={name}
            placeholder={"Artist Name *"}
            name="ArtistName"
          />

          <div className="my-3">
            <input
              ref={fileInputRef}
              accept="image/*"
              style={{ display: "none" }}
              id="avatar-upload"
              type="file"
              onChange={handleFileChange}
            />
            <span
              onClick={handleUploadClick}
              htmlFor="avatar-upload"
              className="w-full bg-primary text-md text-white py-3 px-4 rounded-lg font-medium hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3 cursor-pointer"
            >
              Upload Profile Photo (Max 2MB)
            </span>
          </div>

          {previewUrl && (
            <motion.div
              className="my-2 flex justify-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <img src={previewUrl} alt="Avatar" className="w-24 h-24 rounded-full object-cover" />
            </motion.div>
          )}

          <button
            type="submit"
            className="w-full bg-primary text-lg text-white py-3 px-4 rounded-lg font-medium hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3 cursor-pointer disabled:cursor-default disabled:opacity-50 disabled:hover:translate-y-0 disabled:hover:shadow-none"
            disabled={isLoadingUploadArtist}
          >
            {isLoadingUploadArtist ? (
              <div className="my-2 flex justify-center">
                <CircularProgress color={"#2A3747"} />
              </div>
            ) : (
              "Add Artist"
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AddArtist;
