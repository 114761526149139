import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import GigForm from "./../GigForm/GigForm";

const EditGig = () => {
  const [gig, setGig] = useState(null);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    fetchGig();
  }, [id]);

  const fetchGig = async () => {
    setLoading(true);
    const { data, error } = await supabase.from("gigs").select("*").eq("id", id).single();

    if (error) {
      console.error("Error fetching gig:", error);
      setLoading(false);
    } else {
      setGig(data);
      setLoading(false);
    }
  };

  const handleUpdateSuccess = (updatedGig) => {
    setGig(updatedGig);
    navigate("/");
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="w-10 h-10 border-2 border-primary border-t-transparent border-solid rounded-full animate-spin"></div>
      </div>
    );
  }

  if (!gig) {
    return (
      <div className="bg-red-500 shadow-custom rounded-lg p-8 w-full mb-8 flex justify-center">
        <h6 className="items-center text-white text-xl">Gig not found</h6>
      </div>
    );
  }

  return (
    <div className="max-w-6xl mx-auto p-2 mt-4">
      <GigForm gig={gig} onUpdateSuccess={handleUpdateSuccess} />
    </div>
  );
};

export default EditGig;
