import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoogleAuthCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      window.opener.postMessage({ type: "GOOGLE_AUTH_CODE", code }, "*");
      window.close();
    }
  }, [location]);

  return (
    <div>
      <h2>Authentication successful</h2>
      <p>You can close this window now.</p>
    </div>
  );
};

export default GoogleAuthCallback;
