import React from "react";

const Input = ({ type = "text", name, placeholder, value, isRequired = false, handleFunc, disabled, isGigForm }) => {
  return (
    <div
      className={`relative rounded-lg border-2 ${disabled ? "border-gray-200" : "border-gray-300"} max-h-14 group focus-within:border-primary group-active:border-primary`}
    >
      <input
        type={type}
        name={name}
        value={value}
        required={isRequired}
        onChange={(e) => handleFunc(isGigForm ? e : e.target.value)}
        id={name}
        className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer
        ${disabled ? "text-gray-400 cursor-default" : "text-primary"}`}
        placeholder=" "
        disabled={disabled}
      />
      <label
        htmlFor={name}
        className={`absolute text-md 
      ${disabled ? "text-gray-400" : "text-gray-500"} 
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 bg-white peer-focus:bg-white whitespace-nowrap overflow-hidden text-ellipsis max-w-[calc(100%-24px)] peer-focus:max-w-none`}
      >
        {placeholder}
      </label>
    </div>
  );
};

export default Input;
