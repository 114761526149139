import React, { useState } from "react";
import { useLoadScript } from "@react-google-maps/api";
import toast from "react-hot-toast";
import { useGigForm } from "../../hooks/useGigForm";
import { addGigToCalendar, updateGigInCalendar } from "../../utils/icalendarUtils";
import { CircularProgress } from "../UI";
import Accommodation from "./components/Accommodation";
import BasicInformation from "./components/BasicInformation";
import Contacts from "./components/Contacts";
import EventDetails from "./components/EventDetails";
import Transportation from "./components/Transportation";
import TravelDetails from "./components/TravelDetails";

const GigForm = ({ gig, onUpdateSuccess, isAdmin }) => {
  const {
    gigData,
    setGigData,
    artists,
    useDummyData,
    loading,
    handleChange,
    handleDateChange,
    handleTimeChange,
    handleSubmit,
    handleUseDummyData,
    handleFileUpload,
  } = useGigForm(gig);

  const [venueSelection, setVenueSelection] = useState(null);
  const [generalAttachments, setGeneralAttachments] = useState(gigData.attachments?.general_attachments || []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    libraries: ["places"],
  });

  const handleVenueSelect = (place) => {
    console.log("Selected place:", place, venueSelection);
    setVenueSelection(place);
  };

  const handleGeneralAttachmentChange = (event) => {
    const file = event.target.files[0];
    if (file && file.size <= 5 * 1024 * 1024) {
      // 5MB limit
      handleFileUpload(file, "general_attachments").then((url) => {
        const newAttachments = [...generalAttachments, { name: file.name, url }];
        setGeneralAttachments(newAttachments);
        setGigData((prevData) => ({
          ...prevData,
          attachments: {
            ...prevData.attachments,
            general_attachments: newAttachments,
          },
        }));
      });
    } else {
      alert("File size should not exceed 5MB");
    }
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";

  const onSubmit = async (event) => {
    event.preventDefault();

    // Validate required fields in Basic Information
    const requiredFields = ["artist", "date", "event_name", "venue_name", "venue_address", "city", "state", "country"];
    const missingFields = requiredFields.filter((field) => !gigData[field]);

    if (missingFields.length > 0) {
      toast.error(`Please fill in all required fields: ${missingFields.join(", ")}.`, { id: "fillAllRequiredFields" });
      return;
    }

    // Prepare data for submission
    const submissionData = {
      ...gigData,
      duration_hours: gigData.duration_hours || null,
      duration_minutes: gigData.duration_minutes || null,
    };

    try {
      const result = await handleSubmit(event, submissionData);
      if (result) {
        if (gig) {
          // Updating existing gig
          await updateGigInCalendar(result.artist, result);
        } else {
          // Adding new gig
          await addGigToCalendar(result.artist, result);
        }

        if (onUpdateSuccess) {
          onUpdateSuccess(result);
        }

        toast.remove();
        toast.success(gig ? "Gig updated successfully!" : "Gig created successfully!", { id: "gigSuccess" });
      }
    } catch (error) {
      console.error("Error submitting gig:", error);

      toast.error(gig ? "Error updating gig. Please try again." : "Error creating gig. Please try again.", {
        id: "gigError",
      });
    }
  };

  return (
    <div className="w-full bg-white shadow-custom rounded-xl flex flex-col p-6 mt-2">
      <h1 className="font-medium text-primary text-3xl">
        {gig ? `Edit Gig: ${gig.artists?.name || "Artist"} - ${gig.event_name || "Event"}` : "Create New Gig"}
      </h1>

      <form className="mt-5" onSubmit={onSubmit}>
        <div className="grid grid-cols-12 gap-5">
          {/* Button to use dummy data - only visible for admin users */}
          {isAdmin && (
            <div className="col-span-12">
              <button
                type="button"
                onClick={handleUseDummyData}
                disabled={useDummyData || loading}
                className="text-sm bg-white text-secondary border border-secondary py-3 rounded-[20px] px-6 font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
              >
                Use Dummy Data
              </button>
            </div>
          )}

          {/* Basic Information */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Basic Information</h6>
          </div>
          <BasicInformation
            gigData={gigData}
            artists={artists}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleVenueSelect={handleVenueSelect}
            isLoaded={isLoaded}
          />

          {/* Travel Details */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Travel Details</h6>
          </div>
          <TravelDetails
            gigData={gigData}
            handleChange={handleChange}
            handleDateChange={handleDateChange}
            handleTimeChange={handleTimeChange}
            handleFileUpload={handleFileUpload}
          />

          {/* Event Details */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Event Details</h6>
          </div>
          <EventDetails gigData={gigData} handleChange={handleChange} handleTimeChange={handleTimeChange} />

          {/* Transportation */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Transportation</h6>
          </div>
          <Transportation gigData={gigData} handleChange={handleChange} handleTimeChange={handleTimeChange} />

          {/* Accommodation */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Accommodation</h6>
          </div>
          <Accommodation gigData={gigData} handleChange={handleChange} isLoaded={isLoaded} />

          {/* Contacts */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Contacts</h6>
          </div>
          <Contacts gigData={gigData} handleChange={handleChange} />

          {/* Attachments */}
          <div className="col-span-12">
            <h6 className="font-bold text-secondary text-xl">Attachments</h6>
            {generalAttachments.map((attachment, index) => (
              <span key={index}>{attachment.name}</span>
            ))}
            {generalAttachments.length < 8 && (
              <div className="mt-2">
                <label
                  className={`bg-white border-2 border-primary text-primary text-sm py-2 px-4 rounded-[20px] font-medium transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom cursor-pointer `}
                >
                  Upload File <input type="file" hidden onChange={handleGeneralAttachmentChange} />
                </label>
              </div>
            )}
            {generalAttachments.length >= 8 && <span className="text-red-600">Maximum of 8 attachments reached</span>}
          </div>

          <div className="col-span-12">
            <button
              type="submit"
              disabled={loading}
              className="w-full bg-primary text-md text-white py-3 rounded-3xl font-bold hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
            >
              {loading ? (
                <div className="flex justify-center gap-2 items-center">
                  <div className="mr-2 flex justify-center">
                    <CircularProgress />
                  </div>
                  {gig ? "Updating Gig..." : "Submitting Gig..."}
                </div>
              ) : gig ? (
                "Update Gig"
              ) : (
                "Submit Gig"
              )}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GigForm;
