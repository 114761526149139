import { useEffect, useState } from "react";
import { format, isValid, parse } from "date-fns";
import { dummyGigData } from "../components/GigForm/dummyData";
import { supabase } from "../supabaseClient";
import { addGigToCalendar, updateGigInCalendar } from "../utils/icalendarUtils";

const emptyGigData = {
  date: null,
  event_name: "",
  venue_name: "",
  artist: "",
  city: "",
  state: "",
  country: "",
  travel_mode: "",
  outbound_date: null,
  outbound_time: null,
  return_date: null,
  return_time: null,
  venue_address: "",
  performance_type: "",
  soundcheck_time: null,
  show_time: null,
  doors_open_time: null,
  curfew_time: null,
  duration_hours: "",
  duration_minutes: "",
  stage_name: "",
  lineup: "",
  guest_list: "",
  other_info: "",
  hotel_name: "",
  hotel_address: "",
  hotel_phone: "",
  hotel_website: "",
  hotel_notes: "",
  timezone: null,
  // New flattened fields
  outbound_plane_number: "",
  outbound_train_number: "",
  outbound_confirmation_number: "",
  outbound_train_booking_reference: "",
  outbound_seat: "",
  outbound_ticket_number: "",
  outbound_departure_airport: "",
  outbound_arrival_airport: "",
  outbound_departure_train_station: "",
  outbound_arrival_train_station: "",
  return_number: "",
  return_confirmation_number: "",
  return_train_booking_reference: "",
  return_seat: "",
  return_ticket_number: "",
  return_departure_airport: "",
  return_arrival_airport: "",
  return_departure_train_station: "",
  return_arrival_train_station: "",
  transport_notes: "",
  airport_pickup_contact_name: "",
  airport_pickup_contact_phone: "",
  airport_pickup_instructions: "",
  venue_transportation_type: "",
  venue_transportation_details: "",
  airport_return_contact_name: "",
  airport_return_contact_phone: "",
  airport_return_instructions: "",
  event_contact1_name: "",
  event_contact1_phone: "",
  event_contact1_email: "",
  event_contact1_role: "",
  event_contact2_name: "",
  event_contact2_phone: "",
  event_contact2_email: "",
  event_contact2_role: "",
  airport_return_pickup_time: null,
  venue_transportation_pickup_driver_name: "",
  venue_transportation_pickup_driver_phone: "",
  venue_transportation_pickup_time: null,
  venue_transportation_pickup_driver_notes: "",
  venue_transportation_return_driver_name: "",
  venue_transportation_return_driver_phone: "",
  venue_transportation_return_time: null,
  venue_transportation_return_driver_notes: "",
};

export const useGigForm = (initialGig) => {
  const [gigData, setGigData] = useState(emptyGigData);
  const [artists, setArtists] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: "", severity: "success" });
  const [user, setUser] = useState(null);
  const [useDummyData, setUseDummyData] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserAndArtists = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      setUser(user);

      if (user) {
        const { data, error } = await supabase.from("artists").select("id, name").eq("user_id", user.id);

        if (error) {
          console.error("Error fetching artists:", error);
          setSnackbar({ open: true, message: `Error fetching artists: ${error.message}`, severity: "error" });
        } else {
          setArtists(data);
        }
      }
    };

    fetchUserAndArtists();

    if (initialGig) {
      const formattedGig = formatGigData(initialGig);
      setGigData(formattedGig);
    }
  }, [initialGig]);

  const formatGigData = (gig) => {
    const formattedGig = {
      ...gig,
      date: gig.date ? parse(gig.date, "yyyy-MM-dd", new Date()) : null,
      outbound_date: gig.outbound_date ? parse(gig.outbound_date, "yyyy-MM-dd", new Date()) : null,
      return_date: gig.return_date ? parse(gig.return_date, "yyyy-MM-dd", new Date()) : null,
    };

    const timeFields = [
      "outbound_time",
      "return_time",
      "soundcheck_time",
      "show_time",
      "doors_open_time",
      "curfew_time",
      "airport_return_pickup_time",
      "venue_transportation_pickup_time",
      "venue_transportation_return_time",
    ];
    timeFields.forEach((field) => {
      if (gig[field]) {
        formattedGig[field] = parse(gig[field], "HH:mm:ss", new Date());
      }
    });

    return formattedGig;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log("🚀 ~ handleChange ~ name, value:", name, value);

    setGigData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (field, value) => {
    console.log(field, value);
    setGigData((prevData) => ({
      ...prevData,
      [field]: isValid(value) ? value : null,
    }));
  };

  const handleTimeChange = (field, value) => {
    console.log(value);

    console.log("isValid", isValid(value));

    setGigData((prevData) => ({
      ...prevData,
      [field]: isValid(value) ? value : null,
    }));
  };

  const handleFileUpload = async (file, bucket) => {
    try {
      const { data, error } = await supabase.storage.from(bucket).upload(`${Date.now()}_${file.name}`, file);

      if (error) {
        throw error;
      }

      const { data: urlData } = supabase.storage.from(bucket).getPublicUrl(data.path);

      return urlData.publicUrl;
    } catch (error) {
      console.error("Error uploading file:", error);
      setSnackbar({ open: true, message: `Error uploading file: ${error.message}`, severity: "error" });
      return null;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setSnackbar({ open: true, message: "Saving gig data...", severity: "info" });

    const formatTimeIfValid = (time) => {
      if (time && isValid(time)) {
        try {
          return format(time, "HH:mm:ss");
        } catch (error) {
          console.error(`Error formatting time:`, error);
          return null;
        }
      }
      return null;
    };

    const convertToIntegerOrNull = (value) => {
      if (value === "" || value === null || value === undefined) {
        return null;
      }
      const parsed = parseInt(value, 10);
      return isNaN(parsed) ? null : parsed;
    };

    const formattedData = {
      ...gigData,
      user_id: user.id,
      date: gigData.date ? format(gigData.date, "yyyy-MM-dd") : null,
      outbound_date: gigData.outbound_date ? format(gigData.outbound_date, "yyyy-MM-dd") : null,
      return_date: gigData.return_date ? format(gigData.return_date, "yyyy-MM-dd") : null,
      duration_hours: convertToIntegerOrNull(gigData.duration_hours),
      duration_minutes: convertToIntegerOrNull(gigData.duration_minutes),
    };

    // Format time fields
    [
      "outbound_time",
      "return_time",
      "soundcheck_time",
      "show_time",
      "doors_open_time",
      "curfew_time",
      "airport_return_pickup_time",
      "venue_transportation_pickup_time",
      "venue_transportation_return_time",
    ].forEach((field) => {
      formattedData[field] = formatTimeIfValid(gigData[field]);
    });

    console.log("Formatted data before submission:", formattedData);

    const requiredFields = [
      "date",
      "event_name",
      "venue_name",
      "artist",
      "user_id",
      "venue_address",
      "city",
      "state",
      "country",
    ];
    const missingFields = requiredFields.filter((field) => !formattedData[field]);

    if (missingFields.length > 0) {
      setSnackbar({
        open: true,
        message: `Please fill in all required fields: ${missingFields.join(", ")}`,
        severity: "error",
      });
      setLoading(false);
      return;
    }

    // Check if the selected artist belongs to the user
    const selectedArtist = artists.find((artist) => artist.id === formattedData.artist);
    if (!selectedArtist) {
      setSnackbar({
        open: true,
        message: "Please select a valid artist linked to your account.",
        severity: "error",
      });
      setLoading(false);
      return;
    }

    try {
      let result;
      if (initialGig) {
        // Update existing gig
        const { data, error } = await supabase.from("gigs").update(formattedData).eq("id", initialGig.id).select();

        if (error) throw error;
        result = data[0];

        // Update the gig in the calendar
        await updateGigInCalendar(selectedArtist.id, result);
      } else {
        // Insert new gig
        const { data, error } = await supabase.from("gigs").insert(formattedData).select();

        if (error) throw error;
        result = data[0];

        // Add the new gig to the calendar
        await addGigToCalendar(selectedArtist.id, result);
      }

      console.log("Gig data saved:", result);

      if (!result) {
        throw new Error("No data returned from gig insert/update operation");
      }

      setSnackbar({
        open: true,
        message: `Gig ${initialGig ? "updated" : "created"} successfully and calendar updated!`,
        severity: "success",
      });
      setLoading(false);
      return result;
    } catch (error) {
      console.error("Error submitting gig:", error);
      let errorMessage = `Error ${initialGig ? "updating" : "creating"} gig: ${error.message}`;

      if (error.message.includes("Google") || error.message.includes("Places API")) {
        errorMessage = `Error with Google Places API: ${error.message}. Please try again or enter the venue details manually.`;
      }

      setSnackbar({ open: true, message: errorMessage, severity: "error" });
      setLoading(false);
      return null;
    }
  };

  const handleUseDummyData = () => {
    const formattedDummyData = {
      ...dummyGigData,
      date: parse(dummyGigData.date, "yyyy-MM-dd", new Date()),
      outbound_date: parse(dummyGigData.outbound_date, "yyyy-MM-dd", new Date()),
      return_date: parse(dummyGigData.return_date, "yyyy-MM-dd", new Date()),
    };

    const timeFields = [
      "outbound_time",
      "return_time",
      "soundcheck_time",
      "show_time",
      "doors_open_time",
      "curfew_time",
      "airport_return_pickup_time",
      "venue_transportation_pickup_time",
      "venue_transportation_return_time",
    ];
    timeFields.forEach((field) => {
      if (dummyGigData[field]) {
        formattedDummyData[field] = parse(dummyGigData[field], "HH:mm", new Date());
      }
    });

    // Set the artist field to the first available artist
    if (artists.length > 0) {
      formattedDummyData.artist = artists[0].id;
    }

    setGigData(formattedDummyData);
    setUseDummyData(true);
  };

  return {
    gigData,
    setGigData,
    artists,
    snackbar,
    setSnackbar,
    user,
    useDummyData,
    loading,
    handleChange,
    handleDateChange,
    handleTimeChange,
    handleSubmit,
    handleUseDummyData,
    handleFileUpload,
  };
};
