import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { RiLock2Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { CircularProgress, Input } from "../UI";

const PasswordReset = () => {
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "PASSWORD_RECOVERY") {
        // The user has clicked the magic link and is ready to set a new password
        console.log("Password recovery event detected");
      }
    });

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { error } = await supabase.auth.updateUser({ password: password });

      if (error) throw error;

      toast.success("Password updated successfully!", { id: "successPasswordUpdate" });
      setTimeout(() => navigate("/login"), 5000);
    } catch (error) {
      console.error("Error updating password:", error);

      toast.error(`Error updating password: ${error.message}`, { id: "errorUpdatingPassword" });
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = (password) => setPassword(password);

  return (
    <main className="max-w-[25rem] mx-auto">
      <div className="w-full bg-white shadow-custom rounded-lg flex flex-col p-6 mt-2 items-center">
        <RiLock2Line size={40} className="mb-2" />
        <h1 className="text-primary text-3xl font-bold mb-4">Reset Password</h1>
        <form className="w-full mt-3" onSubmit={handleSubmit} noValidate>
          <Input
            type="password"
            name="password"
            placeholder="New Password *"
            isRequired
            value={password}
            handleFunc={handleChangePassword}
          />

          <button
            type="submit"
            className="w-full bg-primary text-md text-white  py-3 px-4 rounded-lg font-medium hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-4 cursor-pointer disabled:cursor-default disabled:opacity-50 disabled:hover:translate-y-0 disabled:hover:shadow-none"
            disabled={loading}
          >
            {loading ? (
              <div className="my-2 flex justify-center">
                <CircularProgress color={"#2A3747"} />
              </div>
            ) : (
              "Update Password"
            )}
          </button>
        </form>
      </div>
    </main>
  );
};

export default PasswordReset;
