import React from "react";
import * as AvatarRadix from "@radix-ui/react-avatar";
import { supabase } from "../../supabaseClient";
import { getInitials } from "../UserProfile/helpers";

const Avatar = ({ artist, size = 45, className }) => {
  return (
    <AvatarRadix.Root
      style={{ height: `${size}px`, width: `${size}px` }}
      className={`bg-blackA1 inline-flex  select-none items-center justify-center overflow-hidden rounded-full align-middle ${className}`}
    >
      <AvatarRadix.Image
        className="h-full w-full rounded-[inherit] object-cover"
        src={
          artist.avatar_url
            ? `${supabase.storage.from("artist-images").getPublicUrl(artist.avatar_url).data.publicUrl}`
            : ""
        }
        alt={artist.name}
      />
      <AvatarRadix.Fallback
        className="text-violet11 leading-1 flex h-full w-full items-center justify-center bg-gray4 text-[15px] font-medium"
        delayMs={600}
      >
        {getInitials(artist.name)}
      </AvatarRadix.Fallback>
    </AvatarRadix.Root>
  );
};

export default Avatar;
