import React from "react";
import { Link } from "react-router-dom";

const GetStartedBtn = () => {
  return (
    <Link
      to="/login"
      className={`inline-block text-base sm:text-lg font-bold bg-secondary text-black rounded-lg py-2.5 px-6 mt-16 transition-all duration-300 ease-in-out transform hover:-translate-y-0.5 hover:bg-secondaryDark hover:shadow-lg`}
    >
      Get Started for Free!
    </Link>
  );
};

export default GetStartedBtn;
