import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Input } from "../../UI";

const Accommodation = ({ gigData, handleChange, isLoaded }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      const fieldsMapping = {
        name: "hotel_name",
        formatted_address: "hotel_address",
        formatted_phone_number: "hotel_phone",
        website: "hotel_website",
      };

      Object.keys(fieldsMapping).forEach((key) => {
        if (place[key]) {
          handleChange({ target: { name: fieldsMapping[key], value: place[key] } });
        }
      });
    }
  };

  if (!isLoaded) return null;

  return (
    <>
      <div className="col-span-12 sm:col-span-6">
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <Input
            placeholder={"Hotel Name"}
            name={"hotel_name"}
            value={gigData.hotel_name}
            isGigForm
            handleFunc={handleChange}
          />
        </Autocomplete>
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Input
          placeholder={"Hotel Address"}
          name={"hotel_address"}
          value={gigData.hotel_address}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Hotel Phone"}
          name={"hotel_phone"}
          value={gigData.hotel_phone}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Hotel Website"}
          name={"hotel_website"}
          value={gigData.hotel_website}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          placeholder={"Hotel Notes"}
          name={"hotel_notes"}
          value={gigData.hotel_notes}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
    </>
  );
};

export default Accommodation;
