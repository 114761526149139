import { useEffect, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { MdAccessTime } from "react-icons/md";

const extractHoursAndMinutes = (dateString) => {
  const date = new Date(dateString);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  const amPmSelected = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12;

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  return { formattedHours: `${formattedHours}`, minutes: `${formattedMinutes}`, amPmSelected };
};

const TimeSelector = ({ selectedTime, handleChangeFunc, name, placeholder }) => {
  const [show, setShow] = useState(false);
  const [amPm, setAmPm] = useState("AM");
  const [selectedHour, setSelectedHour] = useState(null);
  const [selectedMinute, setSelectedMinute] = useState(null);
  const [titleTime, setTitleTime] = useState("");

  useEffect(() => {
    if (!selectedTime) return;

    const { formattedHours, minutes, amPmSelected } = extractHoursAndMinutes(selectedTime);

    setSelectedHour(formattedHours);
    setAmPm(amPmSelected);
    setSelectedMinute(minutes);
  }, [selectedTime]);

  const handleSelectTime = () => {
    const currentDate = new Date();

    let hour = parseInt(selectedHour, 10);
    if (amPm === "PM" && hour !== 12) {
      hour += 12;
    } else if (amPm === "AM" && hour === 12) {
      hour = 0;
    }

    currentDate.setHours(hour);
    currentDate.setMinutes(parseInt(selectedMinute, 10));
    currentDate.setSeconds(0);

    const formattedDate = format(currentDate, "EEE MMM dd yyyy HH:mm:ss OOOO");

    const userLanguage = navigator.language || "en-US";

    const timeZoneName = Intl.DateTimeFormat(userLanguage, { timeZoneName: "long" })
      .formatToParts(currentDate)
      .find((part) => part.type === "timeZoneName").value;

    const finalFormattedDate = new Date(`${formattedDate} (${timeZoneName})`);

    handleChangeFunc(name, finalFormattedDate);

    setShow(false);
  };

  const handleClickOutside = useClickAway(() => {
    setShow(false);
  });

  const handleChangeSelectHour = (hour) => {
    setSelectedHour(hour);

    if (!selectedMinute) {
      setSelectedMinute("00");
    }
  };

  useEffect(() => {
    if (!show && !selectedTime) return;
    const hour = selectedHour ? selectedHour.toString().padStart(2, "0") : "hh";
    const minute = selectedMinute ? selectedMinute.toString().padStart(2, "0") : "mm";
    const period = amPm || "aa";

    setTitleTime(`${hour}:${minute} ${period}`);
  }, [selectedMinute, selectedHour, amPm, selectedTime, show]);

  return (
    <div className="relative w-full" ref={handleClickOutside}>
      <div
        className={`relative rounded-lg border-2 max-h-14 group border-gray-300 focus-within:border-primary group-active:border-primary `}
      >
        <input
          type={"text"}
          name={name}
          value={titleTime}
          onClick={() => setShow(true)}
          readOnly
          className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer text-primary cursor-pointer`}
          placeholder=" "
        />
        <MdAccessTime className="absolute right-3 size-6 top-1/2 transform -translate-y-1/2 text-gray-400" />
        <label
          htmlFor={name}
          onClick={() => setShow(true)}
          className={`absolute text-md 
text-gray-500
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 bg-white cursor-pointer`}
        >
          {placeholder}
        </label>

        {show && (
          <div className="bg-white  w-full absolute top-16 z-[99] p-5 shadow-custom rounded-lg flex flex-col gap-4 justify-between">
            <div className="flex justify-between gap-1">
              <div className="w-1/2 max-h-[200px]">
                <span>Hours</span>
                <ul className="w-full max-h-[200px] overflow-y-auto">
                  {Array.from({ length: 12 }, (_, i) => {
                    const hour = (i + 1).toString().padStart(2, "0");
                    return (
                      <li key={hour} className="hover:bg-gray-200 cursor-pointer">
                        <button
                          type="button"
                          className={`w-full text-left p-2 ${selectedHour === hour ? "bg-primary text-white" : ""}`}
                          onClick={() => handleChangeSelectHour(hour)}
                        >
                          {hour}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="w-1/2 max-h-[200px]">
                <span>Minutes</span>
                <ul className="w-full max-h-[200px] overflow-y-auto">
                  {["00", "05", "10", "15", "20", "25", "30", "35", "40", "45", "50", "55"].map((minute, index) => (
                    <li key={index} className=" hover:bg-gray-200 cursor-pointer">
                      <button
                        type="button"
                        className={`w-full text-left p-2 ${selectedMinute === minute ? "bg-primary text-white" : ""}`}
                        onClick={() => setSelectedMinute(minute)}
                      >
                        {minute}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="flex justify-between gap-2 mt-6 ">
              <button
                type="button"
                onClick={() => setAmPm("AM")}
                className={`w-full text-md px-4 py-2 ${amPm === "AM" ? "bg-primary text-white border-primary" : "bg-white text-primary border-primary"} border-2  rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3`}
              >
                AM
              </button>
              <button
                type="button"
                onClick={() => setAmPm("PM")}
                className={`w-full text-md px-4 py-2 ${amPm === "PM" ? "bg-primary text-white border-primary" : "bg-white text-primary border-primary"} border-2  rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3`}
              >
                PM
              </button>
            </div>

            <div>
              <button
                disabled={!selectedHour}
                type="button"
                onClick={handleSelectTime}
                className={` ${
                  !selectedHour
                    ? "bg-gray-400 text-gray-200 border-gray-400 cursor-not-allowed"
                    : "bg-primary text-white border-primary duration-300 hover:-translate-y-0.5 hover:shadow-custom"
                } w-full text-md px-4 py-2  bg-primary text-white border-primary border-2  rounded-lg font-medium  transition-all mt-3`}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TimeSelector;
