import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import { deleteGigFromCalendar } from "../../utils/icalendarUtils";
import GigCalendar from "../Calendar";
import { Avatar } from "./../UI";
import UpcomingGigSection from "./components/UpcomingGigSection";

const HomePage = () => {
  const [upcomingGigs, setUpcomingGigs] = useState([]);
  const [selectedGig, setSelectedGig] = useState(null);
  const [isLoadingFetchUpcomingGigs, setIsLoadingFetchUpcomingGigs] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUpcomingGigs();
  }, []);

  const fetchUpcomingGigs = async () => {
    const {
      data: { user },
    } = await supabase.auth.getUser();

    if (!user) {
      console.error("No user logged in");
      return;
    }

    const { data: userArtists, error: artistError } = await supabase
      .from("artists")
      .select("id")
      .eq("user_id", user.id);

    if (artistError) {
      console.error("Error fetching user artists:", artistError);
      return;
    }

    const artistIds = userArtists.map((artist) => artist.id);

    const { data, error } = await supabase
      .from("gigs")
      .select(
        `
        *,
        artists:artist (id, name, avatar_url)
      `
      )
      .in("artist", artistIds)
      .order("date", { ascending: true });

    if (error) {
      // toast.error(`Error fetching upcoming gigs: ${error}`, { id: "errorFetchingUpcomingGigs" });
      setIsLoadingFetchUpcomingGigs(false);
    } else {
      setIsLoadingFetchUpcomingGigs(false);
      setUpcomingGigs(data || []);
    }
  };

  const groupGigsByArtist = () => {
    const groupedGigs = {};
    upcomingGigs.forEach((gig) => {
      const artistId = gig.artist;
      const artistName = gig.artists?.name || "Unknown Artist";
      if (!groupedGigs[artistId]) {
        groupedGigs[artistId] = { gigs: [], artist: gig.artists || { id: artistId, name: artistName } };
      }
      groupedGigs[artistId].gigs.push(gig);
    });

    // Sort the grouped gigs by artist name
    const sortedGroupedGigs = Object.entries(groupedGigs).sort((a, b) =>
      a[1].artist.name.localeCompare(b[1].artist.name)
    );

    return sortedGroupedGigs;
  };

  const handleGigClick = (gig) => {
    setSelectedGig(gig);
  };

  const handleCloseModal = () => {
    setSelectedGig(null);
  };

  const handleEditGig = (gig) => {
    navigate(`/edit-gig/${gig.id}`);
  };

  const handleDeleteGig = async (gig) => {
    try {
      // Delete gig from the database
      const { error } = await supabase.from("gigs").delete().eq("id", gig.id);

      if (error) throw error;

      // Delete gig from the iCalendar
      await deleteGigFromCalendar(gig.artist, gig.id);

      fetchUpcomingGigs();
      toast.remove();
      toast.success("Gig deleted successfully!", { id: "successDeleteGig" });
    } catch (error) {
      console.error("Error deleting gig:", error);
      toast.error("Failed to delete gig. Please try again.", { id: "failedDeleteGig" });
    }
  };

  return (
    <div className="flex flex-col items-start">
      <div className="mb-8 w-full">
        <GigCalendar
          gigs={upcomingGigs}
          onGigClick={handleGigClick}
          onEditGig={handleEditGig}
          onDeleteGig={handleDeleteGig}
        />
      </div>
      <h4 className="text-primary text-2xl mb-1">Upcoming Gigs</h4>

      {isLoadingFetchUpcomingGigs ? (
        <div className="bg-gray-300 rounded-lg w-full mx-auto  h-[300px] animate-pulse" />
      ) : (
        <motion.div
          className="w-full"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
        >
          {upcomingGigs.length === 0 ? (
            <p className="text-xl text-primary">No upcoming gigs scheduled.</p>
          ) : (
            groupGigsByArtist().map(([artistId, { gigs, artist }]) => (
              <div key={artistId} className="bg-white shadow-custom rounded-lg p-8 w-full mb-8">
                <div className="flex items-center mb-2 gap-3">
                  <Avatar artist={artist} />
                  <Link
                    to={`/artists/${artist.id}`}
                    className="text-primary text-xl font-medium hover:text-primaryDark"
                  >
                    {artist.name}
                  </Link>
                </div>

                <UpcomingGigSection
                  gigs={gigs}
                  handleGigClick={handleGigClick}
                  handleCloseModal={handleCloseModal}
                  selectedGig={selectedGig}
                  handleDeleteGig={handleDeleteGig}
                />
              </div>
            ))
          )}
        </motion.div>
      )}
    </div>
  );
};

export default HomePage;
