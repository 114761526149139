import { useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { format } from "date-fns";
import { MdCalendarToday } from "react-icons/md";
import Datepicker from "tailwind-datepicker-react";

const options = {
  title: "",
  autoHide: true,
  todayBtn: false,
  clearBtn: true,
  clearBtnText: "Clear",
  maxDate: new Date("2030-01-01"),
  minDate: new Date(),
  theme: {
    background: "dark:bg-white dark:rounded-lg",
    todayBtn: "rounded-full ",
    clearBtn: "hidden",
    icons: "dark:bg-white dark:text-primary dark:hover:bg-gray-200 dark:hover:text-primary",
    disabledText: "hidden",
    input: "",
    inputIcon: "",
    selected: "bg-primary rounded-xl dark:text-gray-300 dark:hover:bg-primaryDark !text-white",
    text: "dark:text-primary dark:font-medium dark:hover:bg-gray-200 !text-primary hover:!text-white",
  },
  icons: {
    prev: () => <span>{"<"}</span>,
    next: () => <span>{">"}</span>,
  },
  datepickerClassNames: "top-18",
  defaultDate: new Date(new Date().setDate(new Date().getDate() + 1)),
  language: "en",
  disabledDates: [],
  weekDays: ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"],
  inputNameProp: "date",
  inputIdProp: "date",
  inputPlaceholderProp: "Select Date",
  inputDateFormatProp: {
    day: "numeric",
    month: "long",
    year: "numeric",
  },
};

const DateSelector = ({ selectedDate, handleChangeFunc, name, placeholder = "Date *" }) => {
  const [show, setShow] = useState(false);

  const handleChange = (selectedDate) => {
    handleChangeFunc(name, selectedDate);
  };

  const handleClose = (state) => {
    setShow(state);
  };

  const handleClickOutside = useClickAway(() => {
    setShow(false);
  });

  return (
    <div className="relative max-w-[296px]" ref={handleClickOutside}>
      <Datepicker options={{ ...options }} onChange={handleChange} show={show} setShow={handleClose}>
        <div
          className={`relative rounded-lg border-2 max-h-14 group border-gray-300 focus-within:border-primary group-active:border-primary`}
        >
          <input
            type={"text"}
            name={"calendar"}
            value={selectedDate ? format(selectedDate, "MM/dd/yyyy") : show ? "MM/DD/YYYY" : ""}
            onClick={() => setShow(true)}
            readOnly
            className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer text-primary cursor-pointer`}
            placeholder=" "
          />
          <MdCalendarToday className="absolute right-3 size-6 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <label
            onClick={() => setShow(true)}
            htmlFor={"calendar"}
            className={`absolute text-md 
text-gray-500
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 bg-white cursor-pointer`}
          >
            {placeholder}
          </label>
        </div>
      </Datepicker>
    </div>
  );
};

export default DateSelector;
