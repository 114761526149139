import React from "react";

const IconButton = ({ handleFunc, children, className }) => {
  return (
    <button
      onClick={(e) => {
        e.stopPropagation();
        handleFunc();
      }}
      className={`transition-all duration-300 transform hover:scale-110 p-2 rounded-full hover:bg-gray-100 ${className}`}
    >
      {children}
    </button>
  );
};

export default IconButton;
