import { useEffect, useState } from "react";
import { useClickAway } from "@uidotdev/usehooks";
import { MdExpandMore } from "react-icons/md";

const CustomSelect = ({ selectedValue, handleChangeFunc, name, placeholder, options }) => {
  const [show, setShow] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleClickOutside = useClickAway(() => {
    setShow(false);
  });

  const handleSelectValue = (value) => {
    handleChangeFunc({ target: { name, value: value.id } });
    setShow(false);
  };

  useEffect(() => {
    if (selectedValue && !options.length) return;

    const selectedOption = options.find((el) => el.id === selectedValue)?.name ?? null;

    setSelectedOption(selectedOption);
  }, [selectedValue, options]);

  return (
    <div className="relative w-full" ref={handleClickOutside}>
      <div
        className={`relative rounded-lg border-2 max-h-14 group border-gray-300 focus-within:border-primary group-active:border-primary `}
      >
        <input
          type={"text"}
          name={name}
          value={selectedOption}
          onClick={() => setShow(true)}
          readOnly
          className={`block px-3 pt-4 pb-3 w-full text-md bg-transparent rounded-lg border-none focus:outline-none focus:ring-0 peer text-primary cursor-pointer`}
          placeholder=" "
          required
        />
        <MdExpandMore
          className={`absolute right-3 size-6 top-1/2 transform -translate-y-1/2 transition-transform duration-300 ${
            show ? "rotate-180" : "rotate-0"
          } text-gray-400`}
        />
        <label
          htmlFor={name}
          onClick={() => setShow(true)}
          className={`absolute text-md 
text-gray-500
      duration-300 transform -translate-y-4 scale-75 top-1 left-2 z-10 origin-[0] px-2 peer-focus:px-2
      peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2
      peer-focus:top-1 peer-focus:scale-75 peer-focus:-translate-y-4 bg-white cursor-pointer`}
        >
          {placeholder}
        </label>

        {show && (
          <div className="bg-white max-h-[500px] overflow-auto  w-full absolute top-14 z-[99] shadow-custom rounded-lg flex flex-col gap-0 justify-between">
            {options.map((option) => (
              <div
                className={`${selectedValue === option.id ? "bg-primary text-white hover:bg-primaryDark" : "text-primary bg-white hover:bg-gray-200"}  px-5 py-3 cursor-pointer`}
                onClick={() => handleSelectValue(option)}
              >
                {option.name}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
