import React from "react";

const testimonials = [
  {
    text: "As a solo DJ, GigDaddy has been a lifesaver. It's so easy to use and keeps me organized on the road!",
    author: "DJ Stellar",
  },
  {
    text: "Finally, a simple tool that doesn't overcomplicate things. Perfect for our small band's needs.",
    author: "Mike R., Indie Band Guitarist",
  },
  {
    text: "GigDaddy made managing my comedy tour so much easier. And it's free? Unbelievable!",
    author: "Sarah L., Stand-up Comedian",
  },
];

const WhatArtistSayingSection = () => {
  return (
    <div className="mt-24">
      <h4 className={`text-2xl sm:text-3xl text-primary mb-4`}>What Artists Are Saying</h4>

      <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 sm:gap-8 justify-center `}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="col-span-1 bg-white p-6 h-full rounded-lg shadow-custom">
            <p className={"text-sm sm:text-base text-gray-500 mb-4"}>{testimonial.text}</p>
            <p className={`text-xs sm:text-sm text-primary`}>{testimonial.author}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhatArtistSayingSection;
