import React from "react";

const Card = ({ children, onClick = () => {}, isCalendarStyle = false }) => {
  return (
    <div
      onClick={onClick}
      className={`px-4 py-5 flex flex-col bg-white shadow-lg rounded-[12px]  transition-all duration-300 hover:-translate-y-1 hover:shadow-card ${isCalendarStyle ? "cursor-pointer" : "cursor-default"}`}
    >
      {children}
    </div>
  );
};

export default Card;
