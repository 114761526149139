import React, { useEffect, useState } from "react";
import * as DialogRadix from "@radix-ui/react-dialog";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { supabase } from "../../supabaseClient";
import { Input } from "../UI";
import ArtistList from "./components/ArtistList";
import UserProfileSkeleton from "./components/UserProfileSkeleton";

const UserProfile = () => {
  const [user, setUser] = useState(null);
  const [profile, setProfile] = useState(null);
  const [name, setName] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [avatarUrl, setAvatarUrl] = useState("");
  const [artists, setArtists] = useState([]);
  const [newEmail, setNewEmail] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [openPasswordDialog, setOpenPasswordDialog] = useState(false);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  useEffect(() => {
    fetchUserAndArtists();
  }, []);

  const fetchUserAndArtists = async () => {
    try {
      const {
        data: { user },
        error: authError,
      } = await supabase.auth.getUser();
      if (authError) throw authError;

      setUser(user);
      setNewEmail(user.email);
      if (user) {
        let { data: profileData, error: profileError } = await supabase
          .from("profiles")
          .select("*")
          .eq("id", user.id)
          .single();

        if (profileError && profileError.code === "PGRST116") {
          // Profile doesn't exist, create a new one
          const { data: newProfile, error: createError } = await supabase
            .from("profiles")
            .insert({ id: user.id, name: "", avatar_url: "", role: "normal" })
            .select()
            .single();

          if (createError) throw createError;
          profileData = newProfile;
        } else if (profileError) {
          throw profileError;
        }

        setProfile(profileData);
        setName(profileData.name || "");
        setAvatarUrl(profileData.avatar_url || "");

        const { data: artistsData, error: artistsError } = await supabase
          .from("artists")
          .select("*")
          .eq("user_id", user.id);

        if (artistsError) throw artistsError;
        setArtists(artistsData || []);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`, { id: "errorFetchingData" });
    }
  };

  const handleNameChange = (name) => setName(name);
  const handleNewEmailChange = (email) => setNewEmail(email);
  const handleCurrentPasswordChange = (password) => setCurrentPassword(password);
  const handleNewPasswordChange = (password) => setNewPassword(password);
  const handleConfirmNewPasswordChange = (password) => setConfirmNewPassword(password);

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setAvatar(file);
      setAvatarUrl(URL.createObjectURL(file));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user) {
      toast.error("Error: No user found", { id: "noUserFound" });
      return;
    }

    try {
      let avatarPath = avatarUrl;

      if (avatar) {
        const fileExt = avatar.name.split(".").pop();
        const fileName = `${user.id}/${user.id}.${fileExt}`; // Include user ID in the path
        const { data, error: uploadError } = await supabase.storage
          .from("avatars")
          .upload(fileName, avatar, { upsert: true });

        if (uploadError) {
          console.error("Error uploading avatar:", uploadError);
          throw uploadError;
        }

        if (data) {
          const {
            data: { publicUrl },
            error: urlError,
          } = supabase.storage.from("avatars").getPublicUrl(data.path);

          if (urlError) {
            console.error("Error getting public URL:", urlError);
            throw urlError;
          }

          avatarPath = publicUrl;
        } else {
          console.error("No data returned from upload");
          throw new Error("No data returned from upload");
        }
      }

      const { data, error: upsertError } = await supabase
        .from("profiles")
        .upsert({
          id: user.id,
          name,
          avatar_url: avatarPath,
          updated_at: new Date(),
        })
        .select()
        .single();

      if (upsertError) {
        console.error("Error upserting profile:", upsertError);
        throw upsertError;
      }

      setProfile(data);

      toast.success("Profile updated successfully", { id: "profileUpdated" });

      fetchUserAndArtists(); // Refresh the data
    } catch (error) {
      toast.error(`Error updating profile: ${error.message}`, { id: "errorProfileUpdate" });
    }
  };

  const handleEmailChange = async (event) => {
    event.preventDefault();
    if (newEmail === user.email) {
      toast("The new email is the same as your current email.", {
        icon: "ℹ️",
        id: "newEmailEqualCurrent",
      });

      setOpenEmailDialog(false);
      return;
    }
    try {
      const { error } = await supabase.auth.updateUser({
        email: newEmail,
      });

      if (error) throw error;

      toast.success("Email update request sent. Please check your new email for verification.", {
        id: "emailUpdateRequest",
      });
      setOpenEmailDialog(false);
    } catch (error) {
      console.error("Error updating email:", error);

      toast.error(
        error.message.includes("already in use")
          ? "This email is already registered."
          : `Error updating email: ${error.message}`,
        { id: "emailAlreadyUse" }
      );
    }
  };

  const handlePasswordChange = async (event) => {
    event.preventDefault();
    if (newPassword !== confirmNewPassword) {
      toast.error("New passwords do not match", { id: "newPasswordDoNotMatch" });
      return;
    }
    try {
      // First, verify the current password
      const { error: signInError } = await supabase.auth.signInWithPassword({
        email: user.email,
        password: currentPassword,
      });

      if (signInError) {
        toast.error("Current password is incorrect", { id: "currentPasswordIsIncorrect" });
        return;
      }

      // If current password is correct, proceed with password update
      const { error: updateError } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (updateError) throw updateError;

      toast.success("Password updated successfully", { id: "passwordUpdatedSuccessfully" });
      setOpenPasswordDialog(false);
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error(`Error updating password: ${error.message}`, { id: "errorUpdatingPasword" });
    }
  };

  if (!user || !profile) {
    return <UserProfileSkeleton />;
  }

  const isEmailProvider = user.app_metadata?.provider === "email";
  const emailLabel = isEmailProvider ? "Login Email" : "Login Email (via Google OAuth)";
  const isAdmin = profile.role === "admin";

  return (
    <div className="flex flex-col items-center mt-16">
      <motion.div
        className="bg-white shadow-custom rounded-lg p-8 max-w-md w-full mb-8"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 0.5 }}
      >
        <h4 className="text-3xl mb-3 font-medium text-primary">My Profile</h4>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col items-center mb-12">
            <img src={avatarUrl} alt="Avatar" className="w-24 h-24 rounded-full mb-4 object-cover" />
            <input accept="image/*" id="avatar-upload" type="file" className="hidden" onChange={handleAvatarChange} />
            <label htmlFor="avatar-upload" className="mt-3">
              <span className="w-full bg-primary text-white py-3 px-4 rounded-lg font-medium hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom cursor-pointer">
                Upload Photo
              </span>
            </label>
          </div>

          <div className="flex flex-col gap-4">
            <Input type="text" name="name" placeholder="Name" isRequired value={name} handleFunc={handleNameChange} />

            <Input name={emailLabel} placeholder={emailLabel} value={user.email} handleFunc={() => {}} disabled />

            {isAdmin && <Input name="Role" placeholder={"Role"} value={"Admin"} onChange={() => {}} disabled />}
          </div>

          {isEmailProvider && (
            <>
              <button
                type="button"
                onClick={() => setOpenEmailDialog(true)}
                className="w-full text-sm bg-white text-primary border-2 border-primary py-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
              >
                Change Email
              </button>

              <button
                type="button"
                onClick={() => setOpenPasswordDialog(true)}
                className="w-full text-sm bg-white text-primary border-2 border-primary py-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
              >
                Change Password
              </button>
            </>
          )}
          <button
            type="submit"
            className="w-full bg-primary text-white py-3 rounded-lg font-medium hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
          >
            Save Changes
          </button>
        </form>
      </motion.div>

      <motion.div
        className="bg-white shadow-custom rounded-lg p-8 max-w-md w-full mb-8"
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h5 className="text-2xl mb-4"> Your Artists</h5>
        <ArtistList artists={artists} />
      </motion.div>

      <DialogRadix.Root open={openEmailDialog} onOpenChange={setOpenEmailDialog}>
        <DialogRadix.Portal>
          <form onSubmit={handleEmailChange}>
            <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
            <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[575px] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <DialogRadix.Title className="mb-6 text-primary text-lg font-bold">Change Email</DialogRadix.Title>
              <DialogRadix.Description className="mb-4 text-primary text-md">
                Please enter your new email address. You will need to verify this email to complete the change.
              </DialogRadix.Description>

              <Input
                type={"email"}
                name="New Email"
                placeholder="New Email"
                value={newEmail}
                handleFunc={handleNewEmailChange}
              />

              <div className="flex mt-6 gap-5 justify-end">
                <DialogRadix.Close asChild>
                  <button className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3">
                    Close
                  </button>
                </DialogRadix.Close>

                <button
                  type="submit"
                  className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3"
                >
                  Save changes
                </button>
              </div>
            </DialogRadix.Content>
          </form>
        </DialogRadix.Portal>
      </DialogRadix.Root>

      <DialogRadix.Root open={openPasswordDialog} onOpenChange={setOpenPasswordDialog}>
        <DialogRadix.Portal>
          <form onSubmit={handlePasswordChange}>
            <DialogRadix.Overlay className="bg-blackA8 data-[state=open]:animate-overlayShow fixed inset-0 z-[9999]" />
            <DialogRadix.Content className="data-[state=open]:animate-contentShow fixed z-[99999] top-[50%] left-[50%] max-w-[575px] max-h-[85vh] w-[90vw] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
              <DialogRadix.Title className="mb-6 text-primary text-xl font-bold">Change Password</DialogRadix.Title>
              <DialogRadix.Description className="mb-4 text-primary text-md">
                Please enter your current password and then your new password.
              </DialogRadix.Description>
              <div className="flex flex-col gap-3">
                <Input
                  type={"password"}
                  name="Current Password"
                  placeholder="Current Password"
                  handleFunc={handleCurrentPasswordChange}
                />
                <Input
                  type={"password"}
                  name="New Password"
                  placeholder="New Password"
                  handleFunc={handleNewPasswordChange}
                />
                <Input
                  type={"password"}
                  name="Confirm New Password"
                  placeholder="Confirm New Password"
                  handleFunc={handleConfirmNewPasswordChange}
                />
              </div>

              <div className="flex mt-6 gap-5 justify-end">
                <DialogRadix.Close asChild>
                  <button className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3">
                    Close
                  </button>
                </DialogRadix.Close>

                <button
                  type="submit"
                  className="text-sm bg-white text-primary p-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:bg-gray-100 hover:shadow-custom mt-3"
                >
                  Change Password
                </button>
              </div>
            </DialogRadix.Content>
          </form>
        </DialogRadix.Portal>
      </DialogRadix.Root>
    </div>
  );
};

export default UserProfile;
