import { addDays, addMonths, format } from 'date-fns';

const today = new Date();
const eventDate = addMonths(today, 1);
const outboundDate = addDays(eventDate, -1);
const returnDate = addDays(eventDate, 1);

export const dummyGigData = {
  // Basic Information
  date: format(eventDate, 'yyyy-MM-dd'),
  event_name: 'NEW Summer Beats Festival',
  venue_name: 'Riverside Arena',
  artist: '', // This should be set to a valid artist ID when using the dummy data
  city: 'Los Angeles',
  state: 'CA',
  country: 'USA',
  venue_address: '123 Riverside Drive, Los Angeles, CA 90012',

  // Travel Details
  travel_mode: 'plane',
  outbound_date: format(outboundDate, 'yyyy-MM-dd'),
  outbound_time: '15:00',
  return_date: format(returnDate, 'yyyy-MM-dd'),
  return_time: '11:00',
  outbound_plane_number: 'FL789',
  outbound_train_number: 'TR456',
  outbound_confirmation_number: 'XYZ456',
  outbound_train_booking_reference: 'BOOK789',
  outbound_departure_airport: 'LAX',
  outbound_arrival_airport: 'JFK',
  outbound_departure_train_station: 'Los Angeles Union Station',
  outbound_arrival_train_station: 'New York Penn Station',
  outbound_seat: '14C',
  outbound_ticket_number: 'TKT123',
  return_number: 'FL987',
  return_confirmation_number: 'ABC789',
  return_train_booking_reference: 'BOOK321',
  return_departure_airport: 'JFK',
  return_arrival_airport: 'LAX',
  return_departure_train_station: 'New York Penn Station',
  return_arrival_train_station: 'Los Angeles Union Station',
  return_seat: '18A',
  return_ticket_number: 'TKT456',
  transport_notes: 'Arrive at airport or train station 2 hours before departure',

  // Event Details
  performance_type: 'DJ Set',
  soundcheck_time: '18:00',
  show_time: '22:00',
  doors_open_time: '20:00',
  curfew_time: '02:00',
  duration_hours: '4',
  duration_minutes: '0',
  stage_name: 'Main Stage',
  lineup: 'DJ Awesome, DJ Fantastic, DJ Incredible',
  guest_list: 'VIP: Alex Johnson, Sam Smith',
  other_info: 'Bring backup USB drives',

  // Transportation
  airport_pickup_contact_name: 'John',
  airport_pickup_contact_phone: '555-1234',
  airport_pickup_instructions: 'Look for sign with your name',
  venue_transportation_pickup_driver_name: 'Emily',
  venue_transportation_pickup_driver_phone: '555-5678',
  venue_transportation_pickup_time: '20:00',
  venue_transportation_pickup_driver_notes: 'Will meet at hotel entrance',
  venue_transportation_return_driver_name: 'David',
  venue_transportation_return_driver_phone: '555-9876',
  venue_transportation_return_time: '02:30',
  venue_transportation_return_driver_notes: 'Will be waiting at artist exit',
  airport_return_contact_name: 'Michael',
  airport_return_contact_phone: '555-4321',
  airport_return_pickup_time: '08:00', // Added this field
  airport_return_instructions: 'Black SUV',
  transportation_notes: 'Please confirm all transportation details 24 hours before the event.',

  // Accommodation
  hotel_name: 'Sunset Grand Hotel',
  hotel_address: '789 Sunset Blvd, Los Angeles, CA 90028',
  hotel_phone: '555-7890',
  hotel_website: 'www.sunsetgrandhotel.com',
  hotel_notes: 'Early check-in requested',

  // Contacts
  event_contact1_name: 'Laura Thompson',
  event_contact1_phone: '555-1111',
  event_contact1_role: 'Event Producer',
  event_contact2_name: 'Chris Anderson',
  event_contact2_phone: '555-2222',
  event_contact2_role: 'Stage Manager',

  // Additional Details
  timezone: 'America/Los_Angeles',
};