import React from "react";

const ArtistProfileSkeleton = () => {
  return (
    <div className="flex flex-col items-center mt-16 gap-8">
      <div className="bg-gray-300 rounded-lg max-w-4xl mx-auto w-full h-[600px] animate-pulse"></div>
    </div>
  );
};

export default ArtistProfileSkeleton;
