import React from "react";
import { Footer } from "../UI";
import GetStartedBtn from "./components/GetStartedBtn";
import LandingPageContent from "./components/LandingPageContent";
import LandingPageTitle from "./components/LandingPageTitle";
import WhatArtistSayingSection from "./components/WhatArtistSayingSection";

const LandingPage = () => {
  return (
    <>
      <div className="max-w-screen-lg mx-auto">
        <div className="my-8 text-center">
          <LandingPageTitle />
          <LandingPageContent />
          <GetStartedBtn />
          <WhatArtistSayingSection />
        </div>
      </div>

      <Footer />
    </>
  );
};

export default LandingPage;
