let loadScriptPromise;

export const loadGoogleMapsScript = () => {
  if (!loadScriptPromise) {
    loadScriptPromise = new Promise((resolve, reject) => {
      // Define initMap function before loading the script
      window.initMap = () => {
        resolve(window.google);
      };

      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_PLACES_API_KEY}&libraries=places&callback=initMap`;
      script.async = true;
      script.defer = true;
      
      script.onerror = reject;

      document.head.appendChild(script);
    });
  }

  return loadScriptPromise;
};