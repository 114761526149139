import React from "react";
import { FiInfo } from "react-icons/fi";

const AlertBetaInfo = () => {
  return (
    <div className="bg-[#e5f6fd] border-none text-[#014361] px-4 py-3 rounded mb-4 flex justify-center gap-4">
      <FiInfo size={"20px"} />
      <p className="font-bold text-center">
        We're in BETA! Help us improve by testing and reporting any bugs you find to{" "}
        <a href="mailto:t@actuallycerulean.com" className="text-[#014361]">
          Trevor! :D
        </a>
      </p>
    </div>
  );
};

export default AlertBetaInfo;
