import React from "react";
import { Input } from "../../UI";

const Contacts = ({ gigData, handleChange }) => {
  return (
    <>
      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Event Contact 1</h6>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Name"}
          name="event_contact1_name"
          value={gigData.event_contact1_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Phone"}
          name="event_contact1_phone"
          value={gigData.event_contact1_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Email"}
          name="event_contact1_email"
          value={gigData.event_contact1_email || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Role"}
          name="event_contact1_role"
          value={gigData.event_contact1_role || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12">
        <h6 className="text-secondaryText font-medium">Event Contact 2</h6>
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Name"}
          name="event_contact2_name"
          value={gigData.event_contact2_name || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Phone"}
          name="event_contact2_phone"
          value={gigData.event_contact2_phone || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Email"}
          name="event_contact2_email"
          value={gigData.event_contact2_email || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-3">
        <Input
          placeholder={"Role"}
          name="event_contact2_role"
          value={gigData.event_contact2_role || ""}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
    </>
  );
};

export default Contacts;
