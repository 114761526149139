import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import ArtistList from "./../UserProfile/components/ArtistList";

const ArtistManagement = () => {
  const [artists, setArtists] = useState([]);
  const [isLoadingArtist, setIsLoadingArtist] = useState(true);

  useEffect(() => {
    fetchArtists();
  }, []);

  const fetchArtists = async () => {
    try {
      const {
        data: { user },
      } = await supabase.auth.getUser();

      if (!user) {
        toast.error("Please log in to view your artists", { id: "noUserLogged" });
        setIsLoadingArtist(false);
        return;
      }

      const { data, error } = await supabase.from("artists").select("*").eq("user_id", user.id);

      if (error) {
        setIsLoadingArtist(false);
        toast.error(`Error fetching artists: ${error.message}`, { id: "errorFetchingArtist" });
      } else {
        setIsLoadingArtist(false);
        setArtists(data || []);
      }
    } catch (error) {
      console.error("Unexpected error fetching artists:", error);
      setIsLoadingArtist(false);
      toast.error(`Unexpected error fetching artists: ${error.message}`, { id: "unexpectedErrorFetchingArtist" });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h4 className="tracking-wide text-3xl mb-3 font-medium text-primary">Artist Management</h4>
        <Link
          to="/artists/add"
          className=" text-sm bg-primary text-white  px-6 py-3 rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3"
        >
          Add New Artist
        </Link>
      </div>

      <ArtistList artists={artists} isLoadingArtist={isLoadingArtist} />
    </div>
  );
};

export default ArtistManagement;
