import React from "react";

const AdminDasboardSkeleton = () => {
  return (
    <div className="flex-grow p-3">
      <h4 className="text-3xl mb-3 font-medium text-primary">Admin Dashboard</h4>

      <div className="grid grid-cols-12 gap-3">
        {[1, 2, 3, 4].map((el) => (
          <div className="col-span-12 sm:col-span-6 md:col-span-3" key={el}>
            <div className="bg-gray-300 rounded-lg max-w-4xl mx-auto w-full h-[105px] animate-pulse" />
          </div>
        ))}

        {[1, 2, 3].map((el) => (
          <div className="col-span-12 sm:col-span-4" key={el}>
            <div className="bg-gray-300 rounded-lg max-w-4xl mx-auto w-full h-[105px] animate-pulse" />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-12 mt-6">
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-1">Artist Growth</h5>

          <div className="flex gap-4 mb-3">
            <div className="bg-gray-300 rounded-lg w-24 h-[45px] animate-pulse" />
            <div className="bg-gray-300 rounded-lg w-24 h-[45px] animate-pulse" />
          </div>

          <div className="bg-gray-300 rounded-lg w-full mx-auto h-[400px] animate-pulse" />
        </div>
      </div>

      <div className="grid grid-cols-12 mt-6">
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-2">Recent Gigs</h5>

          <div className="bg-gray-300 rounded-lg w-full mx-auto h-[305px] animate-pulse" />
        </div>
      </div>

      <div className="grid grid-cols-12 mt-6">
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-2"> Recent User Signups</h5>

          <div className="bg-gray-300 rounded-lg w-full mx-auto h-[305px] animate-pulse" />
        </div>
      </div>
    </div>
  );
};

export default AdminDasboardSkeleton;
