import React from "react";
import CoolCoolEmoji from "./../../../cool-cool-emoji.gif";
import AlertBetaInfo from "./AlertBetaInfo";

const LandingPageTitle = () => {
  return (
    <>
      <div className={`flex  flex-col sm:flex-row  justify-center items-center mb-4`}>
        <img src={CoolCoolEmoji} alt="GigDaddy Logo" className={"h-15 mb-2  sm:h-20 sm:mr-5 sm:mb-0"} />
        <h1 className={` text-4xl sm:text-6xl font-bold tracking-widest text-primary font-sans`}>GIGDADDY</h1>
      </div>

      <AlertBetaInfo />

      <h1 className={"text-[28px]  sm:text-[34px] font-bold text-primary mb-4"}>
        Simple Gig Management for Small Touring Teams
      </h1>

      <h2 className={"text-lg sm:text-2xl italic text-gray-600 mb-2"}>
        Empowering DJs and Artists Touring With Little to No Crew.
      </h2>

      <p className={"text-sm sm:text-base text-gray-600 mb-4"}>
        GigDaddy is the easy-to-use, free tool designed specifically for small touring teams, solo artists, and DJs. We
        understand the challenges of managing your own tours, so we've created a simple solution to keep all your gig
        information organized and accessible.
      </p>
    </>
  );
};

export default LandingPageTitle;
