import React, { useState } from "react";
import { Autocomplete } from "@react-google-maps/api";
import { Input } from "../../UI";
import CustomSelect from "../../UI/CustomSelect";
import DateSelector from "../../UI/DateSelector";

const BasicInformation = ({ gigData, artists, handleChange, handleDateChange, handleVenueSelect, isLoaded }) => {
  const [autocomplete, setAutocomplete] = useState(null);

  const onLoad = (autoC) => setAutocomplete(autoC);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();

      if (place.name) {
        handleChange({ target: { name: "venue_name", value: place.name } });
      }

      if (place.formatted_address) {
        handleChange({ target: { name: "venue_address", value: place.formatted_address } });
      }

      let city = "";
      let state = "";
      let country = "";

      place.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          state = component.short_name;
        }
        if (component.types.includes("country")) {
          country = component.long_name;
        }
      });

      handleChange({ target: { name: "city", value: city } });
      handleChange({ target: { name: "state", value: state } });
      handleChange({ target: { name: "country", value: country } });

      if (handleVenueSelect) {
        handleVenueSelect(place);
      }
    }
  };

  if (!isLoaded) return null;

  return (
    <>
      <div className="col-span-12">
        <CustomSelect
          name={"artist"}
          placeholder={"Artist *"}
          selectedValue={gigData.artist}
          handleChangeFunc={handleChange}
          options={artists}
        />
      </div>

      <div className="col-span-12 sm:col-span-6">
        <DateSelector selectedDate={gigData.date} handleChangeFunc={handleDateChange} name={"date"} />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Input
          isRequired
          placeholder="Event Name *"
          name="event_name"
          value={gigData.event_name}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
          <Input
            isRequired
            placeholder="Venue Name *"
            name="venue_name"
            value={gigData.venue_name}
            isGigForm
            handleFunc={handleChange}
          />
        </Autocomplete>
      </div>
      <div className="col-span-12 sm:col-span-6">
        <Input
          isRequired
          placeholder="Venue Address *"
          name="venue_address"
          value={gigData.venue_address}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input isRequired placeholder="City *" name="city" value={gigData.city} isGigForm handleFunc={handleChange} />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          isRequired
          placeholder="State/Province *"
          name="state"
          value={gigData.state}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
      <div className="col-span-12 sm:col-span-4">
        <Input
          isRequired
          placeholder="Country *"
          name="country"
          value={gigData.country}
          isGigForm
          handleFunc={handleChange}
        />
      </div>
    </>
  );
};

export default BasicInformation;
