import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { supabase } from "../../supabaseClient";
import { getCurrentUser, isAdmin } from "../../utils/auth";
import { Card } from "./../UI";
import AdminDasboardSkeleton from "./components/AdminDasboardSkeleton";

const AdminDashboard = () => {
  const [recentGigs, setRecentGigs] = useState([]);
  const [recentSignups, setRecentSignups] = useState([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalGigs, setTotalGigs] = useState(0);
  const [totalArtists, setTotalArtists] = useState(0);
  const [userRoles, setUserRoles] = useState({ normal: 0, admin: 0 });
  const [gigsLast24Hours, setGigsLast24Hours] = useState(0);
  const [gigsLastWeek, setGigsLastWeek] = useState(0);
  const [gigsLastMonth, setGigsLastMonth] = useState(0);
  const [artistGrowthData, setArtistGrowthData] = useState([]);
  const [chartTimeframe, setChartTimeframe] = useState("daily");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    try {
      const user = await getCurrentUser();

      if (!user) {
        throw new Error("No user logged in");
      }

      const adminStatus = await isAdmin(user);

      if (!adminStatus) {
        throw new Error("Unauthorized: Admin access required");
      }

      await Promise.all([
        fetchRecentGigs(),
        fetchRecentSignups(),
        fetchTotalUsers(),
        fetchTotalGigs(),
        fetchTotalArtists(),
        fetchUserRoles(),
        fetchGigsCreated(),
        fetchArtistGrowth(),
      ]);
    } catch (error) {
      console.error("Error:", error);
      setError(error.message);
      toast.error(error.message, { id: "errorFetchingAdminData", duration: 6000 });
    } finally {
      setLoading(false);
    }
  }

  async function fetchRecentGigs() {
    const { data, error } = await supabase.from("gigs").select("*").order("created_at", { ascending: false }).limit(5);

    if (error) throw error;
    setRecentGigs(data);
  }

  async function fetchRecentSignups() {
    // Fetch recent signups
    const { data: signups, error: signupsError } = await supabase
      .from("profiles")
      .select("id, email, name, created_at")
      .order("created_at", { ascending: false })
      .limit(5);

    if (signupsError) throw signupsError;

    // Fetch artists for each signup
    const signupsWithArtists = await Promise.all(
      signups.map(async (signup) => {
        const { data: artists, error: artistsError } = await supabase
          .from("artists")
          .select("name")
          .eq("user_id", signup.id);

        if (artistsError) throw artistsError;

        return {
          ...signup,
          artists: artists,
        };
      })
    );

    setRecentSignups(signupsWithArtists);
  }

  async function fetchTotalUsers() {
    const { count, error } = await supabase.from("profiles").select("*", { count: "exact", head: true });

    if (error) throw error;
    setTotalUsers(count);
  }

  async function fetchTotalGigs() {
    const { count, error } = await supabase.from("gigs").select("*", { count: "exact", head: true });

    if (error) throw error;
    setTotalGigs(count);
  }

  async function fetchTotalArtists() {
    const { count, error } = await supabase.from("artists").select("*", { count: "exact", head: true });

    if (error) throw error;
    setTotalArtists(count);
  }

  async function fetchUserRoles() {
    const { data, error } = await supabase.from("profiles").select("role");

    if (error) throw error;
    const roles = data.reduce((acc, user) => {
      acc[user.role] = (acc[user.role] || 0) + 1;
      return acc;
    }, {});
    setUserRoles(roles);
  }

  async function fetchGigsCreated() {
    const now = new Date();
    const last24Hours = new Date(now - 24 * 60 * 60 * 1000).toISOString();
    const lastWeek = new Date(now - 7 * 24 * 60 * 60 * 1000).toISOString();
    const lastMonth = new Date(now - 30 * 24 * 60 * 60 * 1000).toISOString();

    const { data, error } = await supabase.from("gigs").select("created_at").gte("created_at", lastMonth);

    if (error) throw error;

    setGigsLast24Hours(data.filter((gig) => gig.created_at >= last24Hours).length);
    setGigsLastWeek(data.filter((gig) => gig.created_at >= lastWeek).length);
    setGigsLastMonth(data.length);
  }

  async function fetchArtistGrowth() {
    const { data, error } = await supabase
      .from("artists")
      .select("created_at")
      .order("created_at", { ascending: true });

    if (error) throw error;

    const growthData = data.reduce((acc, artist) => {
      const date = new Date(artist.created_at).toISOString().split("T")[0];
      acc[date] = (acc[date] || 0) + 1;
      return acc;
    }, {});

    const cumulativeData = Object.entries(growthData).reduce((acc, [date, count], index) => {
      const totalCount = index === 0 ? count : acc[index - 1].totalArtists + count;
      acc.push({ date, newArtists: count, totalArtists: totalCount });
      return acc;
    }, []);

    setArtistGrowthData(cumulativeData);
  }

  if (loading) return <AdminDasboardSkeleton />;
  if (error) return null;

  const totalDetails = [
    { title: "Total Users", value: totalUsers },
    { title: "Total Gigs", value: totalGigs },
    { title: "Total Artists", value: totalArtists },
  ];

  const timeDetails = [
    { title: "Gigs Created (Last 24 Hours)", value: gigsLast24Hours },
    { title: "Gigs Created (Last Week)", value: gigsLastWeek },
    { title: "Gigs Created (Last Month)", value: gigsLastMonth },
  ];

  const motionVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="flex-grow p-3">
      <h4 className="text-3xl mb-3 font-medium text-primary">Admin Dashboard</h4>

      <motion.div initial="hidden" animate="visible" variants={motionVariants}>
        <div className="grid grid-cols-12 gap-3">
          {totalDetails.map((el) => (
            <div className="col-span-12 sm:col-span-6 md:col-span-3" key={el.title}>
              <Card>
                <h3 className="text-secondaryText mb-2">{el.title}</h3>
                <h5 className="text-primary text-2xl">{el.value}</h5>
              </Card>
            </div>
          ))}

          <div className="col-span-12 sm:col-span-6 md:col-span-3">
            <Card>
              <h3 className="text-secondaryText mb-2">User Roles</h3>
              <div className="flex gap-4 items-center h-[32px]">
                <p className="text-primary text-md">Normal: {userRoles.normal || 0}</p>
                <p className="text-primary text-md">Admin: {userRoles.admin || 0}</p>
              </div>
            </Card>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-3 mt-6">
          {timeDetails.map((el) => (
            <div className="col-span-12 sm:col-span-4" key={el.title}>
              <Card>
                <h3 className="text-secondaryText mb-2">{el.title}</h3>
                <h5 className="text-primary text-2xl">{el.value}</h5>
              </Card>
            </div>
          ))}
        </div>
      </motion.div>

      <motion.div className="grid grid-cols-12 mt-6" initial="hidden" animate="visible" variants={motionVariants}>
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-1">Artist Growth</h5>

          <div className="flex gap-4 mb-3">
            <button
              onClick={() => setChartTimeframe("daily")}
              className={`w-24 text-md px-4 py-2 ${chartTimeframe === "daily" ? "bg-primary text-white border-primary" : "bg-white text-primary border-primary"} border-2  rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3`}
            >
              Daily
            </button>
            <button
              onClick={() => setChartTimeframe("weekly")}
              className={`w-24 text-md px-4 py-2 ${chartTimeframe === "weekly" ? "bg-primary text-white border-primary" : "bg-white text-primary border-primary"} border-2  rounded-lg font-medium  transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom mt-3`}
            >
              Weekly
            </button>
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <LineChart
              data={
                chartTimeframe === "daily" ? artistGrowthData : artistGrowthData.filter((_, index) => index % 7 === 0)
              }
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="totalArtists" stroke="#8884d8" name="Total Artists" />
              <Line type="monotone" dataKey="newArtists" stroke="#82ca9d" name="New Artists" />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </motion.div>

      <motion.div className="grid grid-cols-12 mt-6" initial="hidden" animate="visible" variants={motionVariants}>
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-2">Recent Gigs</h5>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  {["Event Name", "Venue", "Date", "Artist"].map((heading) => (
                    <th className="px-6 py-3" key={heading}>
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {recentGigs.map((gig) => (
                  <tr className="bg-white border-b  hover:bg-gray-50" key={gig.artist}>
                    <td className="px-6 py-4">{gig.event_name}</td>
                    <td className="px-6 py-4">{gig.venue_name}</td>
                    <td className="px-6 py-4">{new Date(gig.date).toLocaleDateString()}</td>
                    <td className="px-6 py-4">{gig.artist}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </motion.div>

      <motion.div className="grid grid-cols-12 mt-6" initial="hidden" animate="visible" variants={motionVariants}>
        <div className="col-span-12">
          <h5 className="text-primary text-2xl mb-2"> Recent User Signups</h5>

          <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 ">
                <tr>
                  {["Email", "Name", "Sighup DAte", "Artists Created"].map((heading) => (
                    <th className="px-6 py-3" key={heading}>
                      {heading}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {recentSignups.map((user) => (
                  <tr className="bg-white border-b  hover:bg-gray-50" key={user.id}>
                    <td className={`px-6 py-4 ${!user.email && "text-center"}`}>{user.email || "-"}</td>
                    <td className="px-6 py-4">{user.name || "N/A"}</td>
                    <td className="px-6 py-4">{new Date(user.created_at).toLocaleString()}</td>
                    <td className="px-6 py-4">
                      {user.artists && user.artists.length > 0
                        ? user.artists.map((artist) => artist.name).join(", ")
                        : "None"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default AdminDashboard;
