// src/utils/logger.js

const MAX_LOG_SIZE = 1000000; // 1MB

export const logError = (error) => {
  const timestamp = new Date().toISOString();
  const logEntry = `${timestamp} - Error: ${error.message}\n${error.stack}\n\n`;

  let logs = localStorage.getItem('errorLogs') || '';
  logs = logEntry + logs;

  // Trim logs if they exceed the maximum size
  if (logs.length > MAX_LOG_SIZE) {
    logs = logs.slice(0, MAX_LOG_SIZE);
  }

  localStorage.setItem('errorLogs', logs);
};

export const getLogs = () => {
  return localStorage.getItem('errorLogs') || '';
};

export const clearLogs = () => {
  localStorage.removeItem('errorLogs');
};