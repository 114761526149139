export const getInitials = (name) => {
  if (!name) return;

  const words = name.split(" ");
  if (words.length > 1) {
    return `${words[0][0]}${words[1][0]}`;
  }

  return name[0];
};
