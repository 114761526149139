import React, { useState } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { motion } from "framer-motion";
import toast from "react-hot-toast";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { supabase } from "../../../supabaseClient";
import { Input } from "../../UI";

const getErrorMessage = (error) => {
  switch (error.message) {
    case "Invalid login credentials":
      return "Incorrect email or password. Please try again.";
    case "User already registered":
      return "This email is already registered. Please sign in or use a different email.";
    case "Email not confirmed":
      return "Please confirm your email address before signing in.";
    case "Invalid email":
      return "Please enter a valid email address.";
    case "Password should be at least 6 characters":
      return "Password must be at least 6 characters long.";
    default:
      return "An error occurred. Please try again later.";
  }
};

const AuthTabs = () => {
  const [activeTab, setActiveTab] = useState("signIn");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleAuth = async (e) => {
    e.preventDefault();
    try {
      if (password.length < 6) {
        toast.error("Password must be at least 6 characters long.", { id: "passwordValidate" });

        return;
      }

      if (activeTab === "signUp") {
        if (password !== confirmPassword) {
          toast.error("Passwords do not match", { id: "passwordMatch" });
          return;
        }

        const { error } = await supabase.auth.signUp({
          email,
          password,
          options: {
            emailRedirectTo: `${window.location.origin}/auth/callback`,
          },
        });

        if (error) throw error;

        toast.success("Sign up successful! Please check your email to verify your account before signing in.", {
          id: "signUpSuccessful",
        });
      } else {
        const { error } = await supabase.auth.signInWithPassword({
          email,
          password,
        });

        if (error) throw error;

        toast.success("Sign in successful!", {
          id: "signInSuccessful",
        });
        navigate("/");
      }
    } catch (error) {
      toast.error(getErrorMessage(error), { id: "catchError" });
    }
  };

  const handleChangePassword = (password) => setPassword(password);
  const handleChangeConfirmPassword = (password) => setConfirmPassword(password);
  const handleChangeEmail = (email) => setEmail(email);

  return (
    <Tabs.Root value={activeTab} onValueChange={(newValue) => setActiveTab(newValue)} className="mb-6">
      <Tabs.List className="flex justify-center mb-6">
        <Tabs.Trigger value="signIn" asChild>
          <motion.button
            className={`px-6 py-2 font-bold ${
              activeTab === "signIn" ? "border-b-2 border-primary text-primary" : "text-primary"
            }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Sign In
          </motion.button>
        </Tabs.Trigger>
        <Tabs.Trigger value="signUp" asChild>
          <motion.button
            className={`px-6 py-2 font-bold ${
              activeTab === "signUp" ? "border-b-2 border-primary text-primary" : "text-primary"
            }`}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
          >
            Sign Up
          </motion.button>
        </Tabs.Trigger>
      </Tabs.List>

      <Tabs.Content value="signIn" asChild>
        <motion.div
          key="signIn"
          initial={{ opacity: 0, y: 60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 60 }}
          transition={{ duration: 0.5 }}
        >
          <form onSubmit={handleAuth} className="space-y-4">
            <Input type="email" name="email" placeholder="Email Address" isRequired handleFunc={handleChangeEmail} />
            <Input
              type="password"
              name="password"
              placeholder="Password (min 6 characters)"
              isRequired
              handleFunc={handleChangePassword}
            />
            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-bold hover:bg-primaryDark transition"
            >
              Sign In
            </button>
            <div className="flex justify-end">
              <RouterLink to="/forgot-password" className="text-primary hover:underline">
                Forgot password?
              </RouterLink>
            </div>
          </form>
        </motion.div>
      </Tabs.Content>

      <Tabs.Content value="signUp" asChild>
        <motion.div
          key="signUp"
          initial={{ opacity: 0, y: 60 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 60 }}
          transition={{ duration: 0.5 }}
        >
          <form onSubmit={handleAuth} className="space-y-4">
            <Input type="email" name="email" placeholder="Email Address" isRequired handleFunc={handleChangeEmail} />
            <Input
              type="password"
              name="password"
              placeholder="Password (min 6 characters)"
              isRequired
              handleFunc={handleChangePassword}
            />
            <Input
              type="password"
              name="confirmPassword"
              placeholder="Confirm Password"
              isRequired
              handleFunc={handleChangeConfirmPassword}
            />

            <button
              type="submit"
              className="w-full bg-primary text-white py-3 rounded-lg font-bold hover:bg-primaryDark transition-all duration-300 hover:-translate-y-0.5 hover:shadow-custom"
            >
              Sign Up
            </button>
          </form>
        </motion.div>
      </Tabs.Content>
    </Tabs.Root>
  );
};

export default AuthTabs;
